import Footer from "./Footer";
import Navbar from "./Navbar";
import customer from '../image/mayacustomer.png'
import rideanddelivery from '../image/allinone-removebg.webp'
import BusImage from "../image/largebus-removebg.webp";
import Hotelimage from '../image/hotel.jpeg'
import Restaurantimage from '../image/restaurant.jpg'
import Points from '../image/points.png'
import { Helmet } from "react-helmet-async";
function Terms() {
    return (
        <div className="md:h-screen md:max-h-screen bg-[#F4EFED] flex flex-col font-poppins">
            <Helmet>
                <title>Privacy Policy - Maya-The Super App</title>
                <meta name='description' content="Review the terms and conditions for using Maya's services, including taxi rides, food and courier delivery, bus and flight booking, and hotel reservations. Understand your rights and obligations as a Maya customer or partner." />
   
                <link rel="canonical" href="https://mayanp.com/terms-and-condition" />
            </Helmet>
            <Navbar />
            <div className="text-center py-8 pb-4">
                <h1 className="  text-3xl md:text-4xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">Terms and Conditions</h1>
            </div>
            <div className="container mx-auto px-4 mb-4 text-black">
                <h1 className="text-2xl md:text-3xl font-semibold mb-4 ml-2 font-PPAgrandir">Categories</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 m2 md:md-4">
                    <a href="/terms-and-condition/customer" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl ">
                        <h2 className="text-lg md:text-xl font-semibold">Maya Customer</h2>
                        <div className=" flex items-center text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Terms and conditions applicable to Maya customers...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 ">
                                <img src={customer} alt="Customer" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/terms-and-condition/partner-bus" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl ">
                        <h2 className="text-lg md:text-xl font-semibold">Maya Partner - Bus</h2>
                        <div className=" flex items-center text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Terms and conditions for our bus partners...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 ">
                                <img src={BusImage} alt="Bus Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/terms-and-condition/partner-hotel" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl ">
                        <h2 className="text-lg md:text-xl font-semibold">Maya Partner - Hotel</h2>
                        <div className=" flex items-center text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Terms and conditions for our hotel partners...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 ">
                                <img src={Hotelimage} alt="Hotel Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/terms-and-condition/partner-restaurant" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl ">
                        <h2 className="text-lg md:text-xl font-semibold">Maya Partner - Restaurant</h2>
                        <div className=" flex items-center text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Terms and conditions for our restaurant partners...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 ">
                                <img src={Restaurantimage} alt="Restaurant Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/terms-and-condition/partner-ride-delivery" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl ">
                        <h2 className="text-lg md:text-xl font-semibold">Maya Partner - Ride and Delivery</h2>
                        <div className=" flex items-center text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Terms and conditions for our Ride and Delivery partners...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 md:mt-2">
                                <img src={rideanddelivery} alt="Ride and Delivery Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/terms-and-condition/maya-point" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl ">
                        <h2 className="text-lg md:text-xl font-semibold">Maya Points</h2>
                        <div className=" flex items-center text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Terms and conditions for Maya Points...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 ">
                                <img src={Points} alt="Ride and Delivery Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Terms;
