import React from 'react';
import { useInView } from 'react-intersection-observer';

const ScrollReveal = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.2, // Trigger when 50% of the component is visible
  });

  return (
    <div ref={ref}  className={`transition-all duration-1000 transform ${inView ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
 {children}
    </div>
  );
};

export default ScrollReveal;