import Footer from "./Footer";
import Navbar from "./Navbar";
import serviceImage from '../image/servicedesign.webp'
import Partners from "./Partners";
import ScrollReveal from "./ScrollReveal";
import { Helmet } from "react-helmet-async";
function PartnersPage() {
    return ( 
        <div className=' bg-[#F4EFED] w-full font-poppins'>
          <Helmet>
        <title>Earn with Maya -Maya-The Super App</title>
        <meta name='description' content='Discover diverse earning opportunities by partnering with Maya, whether as a ride, delivery, restaurant, or hotel partner. Start earning flexibly and efficiently today!' />
        <link rel="canonical" href="https://mayanp.com/partner" />
      </Helmet>
      <Navbar />
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[85%] my-8">
          <ScrollReveal>
        <img src={serviceImage} alt="service"  className="w-full h-full object-contain border-4 border-black rounded-3xl"/>
        </ScrollReveal>
        </div>
        
      </div>
      <Partners/>
      <Footer />
    </div>
     );
}

export default PartnersPage;