import React from 'react';
import ScrollReveal from './ScrollReveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const Service = ({ service }) => {
    return (
        <div className="bg-[rgba(6,26,55,.04)] shadow-md p-2 md:p-4 rounded-3xl text-center text-black md:my-4 border-2 border-gray-300 ">


            {/* Mobile view */}
            {/* <div className="md:hidden text-justify">
                <p className="text-black text-sm px-4">{service.description}</p>
            </div>
            <div className="md:hidden mt-2 w-2/3 bg-white shadow-lg rounded-2xl">
                <img
                    src={service.image1}
                    alt="Service"
                    className="w-full h-full object-cover rounded-2xl"
                />
            </div> */}
            <div className="md:hidden relative p-2 text-justify h-72 sm:h-64">
                <div className="w-full ">
                    <h2 className="text-xl font-semibold mb-3 font-PPAgrandir">{service.title}</h2>
                    <p className="text-gray-700 text-sm "><FontAwesomeIcon icon={faQuoteLeft} className='pr-1 mb-2'/>{service.description}<FontAwesomeIcon icon={faQuoteRight} className='pl-1 '/></p>
                </div>
                <div className="absolute bottom-0 right-0 w-1/3  rounded-2xl ml-2 ">
                    <img
                        src={service.image1}
                        alt="Service"
                        className="w-full h-full object-cover rounded-2xl"
                    />
                </div>
            </div>


            {/* Desktop view */}
            <div className="hidden md:flex md:w-full rounded-2xl  justify-between">
                <div className="text-justify md:w-3/5 xl:w-[53%] md:px-4 2xl:w-[55%] mb-2">
                    <h2 className="text-xl md:text-2xl font-semibold mb-3 font-PPAgrandir">{service.title}</h2>
                    <p className="text-gray-700  text-sm md:text-base "><FontAwesomeIcon icon={faQuoteLeft} className='pr-2 mb-2'/><span >{service.description}</span><FontAwesomeIcon icon={faQuoteRight} className='pl-2'/></p>
                </div>

                {service.image2 ? (
                    <div className=" mx-auto w-1/2 max-w-lg rounded-2xl bg-gray-50 shadow-lg md:absolute md:right-0 md:top-1/4 md:mt-0">
                        <img
                            src={service.image1}
                            alt="Service1"
                            className="absolute top-0 left-0 w-1/2 h-full object-cover rounded-tl-2xl rounded-bl-2xl"
                        />
                        <img
                            src={service.image2}
                            alt="Service2"
                            className="absolute right-0 bottom-0 w-1/2 h-3/5 object-cover rounded-tr-2xl rounded-br-2xl"
                        />
                    </div>
                ) : (
                    <div className="md:w-2/5 xl:w-[40%] 2xl:w-[45%] flex items-center justify-center">
                       <ScrollReveal>
                        <img
                            src={service.image1}
                            alt="Service"
                            className="max-w-[98%] max-h-full object-contain rounded-2xl"
                        />
                        </ScrollReveal>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Service;
