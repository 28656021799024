import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
function MayaPointsTerms() {
  return (
    <div className='bg-[#F4EFED]'>
<Navbar/>
    
<div className="bg-[#F4EFED] font-poppins text-gray-700 py-8 px-8 md:p-16  ">
<nav className="mb-4  text-xs md:text-sm">
          <Link to="/terms-and-condition" className="text-[#D60620]">Categories</Link>
          <span className="mx-2"><FontAwesomeIcon icon={faChevronRight} /></span>
          <span className="text-[#D60620] "> Terms of service agreement for maya points</span>
        </nav>
      <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-black font-PPAgrandir">Terms of Service Agreement</h1>
      <div className='text-justify'>
      <p className="mb-4">Maya Multipurpose Pvt Ltd</p>
      <p className="mb-8">Application: Maya</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Maya Points</h2>
      <p className="mb-4">
        <strong>1.1 Description:</strong> Maya Points are a virtual currency issued and managed by Maya Multipurpose Pvt Ltd. Each Maya Point is equivalent to one Nepalese Rupee (1Rs = 1 Maya Point).
      </p>
      <p className="mb-8">
        <strong>1.2 Purpose:</strong> Maya Points are intended for use within the Maya application for various transactions and services, including but not limited to ride bookings, delivery services, Bus Ticketing, Food order, Flight Booking and transfers between users.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Maya Points Wallet</h2>
      <p className="mb-4">
        <strong>2.1 Wallet Feature:</strong> Maya Points are stored and managed within the Maya Points Wallet, a digital wallet provided to users upon registration on the Maya platform.
      </p>
      <p className="mb-8">
        <strong>2.2 Wallet Services:</strong> The Maya Points Wallet allows users to:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>View their Maya Points balance.</li>
        <li>Initiate transactions using Maya Points for eligible services within the Maya application.</li>
        <li>Receive Maya Points from other users via transfers.</li>
        <li>Transfer Maya Points to other users within the Maya application.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Adding Points to the Wallet</h2>
      <p className="mb-4">
        <strong>3.1 Payment Methods:</strong> Users can add Maya Points to their Wallet balance by loading funds through the following payment methods:
      </p>
      <ul className="list-disc list-inside mb-8">
        <li>eSewa: Users can load funds to their Maya Points Wallet using the eSewa payment gateway integrated within the Maya application.</li>
        <li>Mobile Banking: Funds can be added to the Wallet balance via mobile banking services offered by participating banks.</li>
        <li>Internet Banking: Users can initiate fund transfers from their bank accounts to the Maya Points Wallet through internet banking platforms.</li>
        <li>Card Payments: Credit or debit card payments are accepted for adding funds to the Wallet balance securely within the Maya application.</li>
      </ul>
      <p className="mb-8">
        <strong>3.2 Transaction Fees:</strong> Maya Multipurpose Pvt Ltd may impose transaction fees or charges for adding funds to the Maya Points Wallet using certain payment methods. Users will be notified of any applicable fees prior to completing the transaction.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Maya Points Transactions</h2>
      <p className="mb-4">
        <strong>4.1 Eligible Transactions:</strong> Maya Points may be used for eligible transactions within the Maya application, including ride bookings, delivery services, Bus Ticketing, Food order, Flight Booking and transfers between users.
      </p>
      <p className="mb-8">
        <strong>4.2 Transaction Limits:</strong> Maya Multipurpose Pvt Ltd reserves the right to impose limits on Maya Points transactions, including but not limited to minimum and maximum transaction amounts, frequency limits, and restrictions on certain services.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Transfers Between Users</h2>
      <p className="mb-4">
        <strong>5.1 Transfer Process:</strong> Users may transfer Maya Points to other users within the Maya application using the designated transfer feature in the Maya Points Wallet.
      </p>
      <p className="mb-8">
        <strong>5.2 Transfer Limits:</strong> Maya Multipurpose Pvt Ltd reserves the right to impose limits on Maya Points transfers, including but not limited to minimum and maximum transfer amounts, frequency limits, and restrictions based on user activity.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Maya Points Usage</h2>
      <p className="mb-4">
        <strong>6.1 Usage Guidelines:</strong> Maya Points may only be used for eligible transactions and services within the Maya application. Maya Points cannot be redeemed for cash or transferred to external accounts outside of the Maya platform.
      </p>
      <p className="mb-8">
        <strong>6.2 Expiration:</strong> Maya Points do not expire and remain valid for use as long as the user's account remains active on the Maya platform.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">7. Maya Points Redemption</h2>
      <p className="mb-4">
        <strong>7.1 Redemption Process:</strong> Maya Points may be redeemed for eligible services and transactions within the Maya application as designated by Maya Multipurpose Pvt Ltd.
      </p>
      <p className="mb-8">
        <strong>7.2 Redemption Restrictions:</strong> Maya Multipurpose Pvt Ltd reserves the right to impose restrictions on Maya Points redemption, including but not limited to eligibility criteria, redemption limits, and availability of redemption options.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">8. Maya Points Refunds</h2>
      <p className="mb-4">
        <strong>8.1 Refund Policy:</strong> Maya Points transactions are generally non-refundable. In the event of a transaction error or unauthorized activity, users are encouraged to contact Maya customer support for assistance.
      </p>
      <p className="mb-8">
        <strong>9. Maya Points Wallet Security:</strong> Maya Multipurpose Pvt Ltd implements security measures to safeguard Maya Points Wallets and prevent unauthorized access or fraudulent activity. Users are encouraged to maintain the confidentiality of their account credentials and report any suspicious activity to Maya customer support.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">10. Account Suspension or Termination</h2>
      <p className="mb-4">
        <strong>10.1 Suspension or Termination:</strong> Maya Multipurpose Pvt Ltd reserves the right to suspend or terminate a user's access to Maya Points and the Maya Points Wallet in the event of suspected fraud, unauthorized activity, violation of this Agreement, or for any other reason deemed necessary by Maya Multipurpose Pvt Ltd.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">11. Modification of Terms</h2>
      <p className="mb-4">
        <strong>11.1 Updates:</strong> Maya Multipurpose Pvt Ltd reserves the right to modify, update, or revise the terms of this Agreement at any time without prior notice. Users will be notified of any changes, and continued use of Maya Points after such modifications constitutes acceptance of the revised terms.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">12. Governing Law and Jurisdiction</h2>
      <p className="mb-4">
        <strong>12.1 Applicable Law:</strong> This Agreement shall be governed by and construed in accordance with the laws of Nepal. Any disputes arising under this Agreement shall be subject to the exclusive jurisdiction of the courts of Nepal.
      </p>

      <p className="mb-4">
        This Terms of Service Agreement outlines the terms and conditions governing the use and management of Maya Points within the Maya application, including the process of adding points through various payment methods. Should you have any questions or concerns regarding Maya Points or this Agreement, please contact Maya Multipurpose Pvt Ltd at <a href="mailto:support@mayanp.com" className="text-blue-500 underline">support@mayanp.com</a>.
      </p>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default MayaPointsTerms;
