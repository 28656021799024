import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronRight } from "@fortawesome/free-solid-svg-icons";
function ReferAndEarnPolicy() {
  return (
    <div className='bg-[#F4EFED]'>
      <Navbar />
      <div className=" text-gray-700 font-poppins py-4 px-4 md:px-8 text-justify">
      <nav className="mb-4 pt-8 text-xs md:text-sm">
                        <Link to="/privacy" className="text-[#D60620]">Categories</Link>
                        <span className="mx-2"><FontAwesomeIcon icon={faChevronRight}/></span>
                        <span className="text-[#D60620]  ">Refer and Earn Policy</span>
                    </nav>
                    <div className="text-center  pb-8">
          <h1 className=" text-3xl md:text-4xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">Refer and Earn Policy</h1>
        </div>
<div className='mx-4'>
        <p className="mb-4">Maya Multipurpose Pvt Ltd</p>
        <p className="mb-4">Application: Maya</p>
        <p className="mb-4">This Refer and Earn Policy ("Policy") governs the referral programs offered by Maya Multipurpose Pvt Ltd ("Maya") within the Maya application ("App"). By participating in the Refer and Earn programs, referred to as "Normal Refer" and "Diamond Refer," users agree to abide by the terms and conditions outlined herein.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Normal Refer Program</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.1 Eligibility:</h3>
        <p className="mb-4">All registered users of the Maya application are eligible to participate in the Normal Refer program.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.2 Refer and Earn:</h3>
        <p className="mb-4">Users can refer friends, family members, or acquaintances to join Maya and earn rewards. Upon successful registration and verification by Maya, both the referrer and the referee will receive rewards as per the referral program terms.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.3 Reward Criteria:</h3>
        <p className="mb-4">To qualify for rewards, the referred person ("referee") must:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Register on the Maya platform using the referral link or code provided by the referrer.</li>
          <li>Complete the registration process and verify their account.</li>
          <li>Make use of Maya services within a specified timeframe as determined by Maya.</li>
        </ul>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.4 Reward Distribution:</h3>
        <p className="mb-4">Rewards earned through the Normal Refer program are directly credited to the referrer's Maya Points Wallet upon meeting the reward criteria.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Diamond Refer Program</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.1 Eligibility:</h3>
        <p className="mb-4">Participation in the Diamond Refer program is subject to specific eligibility criteria set by Maya. Users may need to meet certain requirements, such as a minimum number of successful referrals, to qualify for the Diamond Refer program.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.2 Refer and Earn:</h3>
        <p className="mb-4">The Diamond Refer program operates on a multi-tiered referral structure, allowing referrers to earn rewards not only from their direct referrals but also from subsequent generations of referrals down to multiple levels.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.3 Tiered Structure:</h3>
        <p className="mb-4">The Diamond Refer program operates on an eight-tiered structure, with rewards distributed based on the depth of referrals and their usage of Maya services.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.4 Reward Distribution:</h3>
        <p className="mb-4">Rewards earned through the Diamond Refer program are distributed as follows:</p>
        <ul className="list-disc list-inside mb-4">
          <li>The referrer earns rewards directly from their direct referrals.</li>
          <li>The referrer also earns rewards from subsequent generations of referrals, up to the eighth stage, based on their activity and usage of Maya services.</li>
        </ul>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">3. General Provisions</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">3.1 Usage Requirement:</h3>
        <p className="mb-4">For both Normal Refer and Diamond Refer programs, referred persons must actively use Maya services at least once to qualify for rewards.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">3.2 Reward Calculation:</h3>
        <p className="mb-4">The calculation of rewards for both programs is based on the referral activity, usage of Maya services by referred persons, and adherence to the terms and conditions of the referral programs.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">3.3 Fraudulent Activity:</h3>
        <p className="mb-4">Maya Multipurpose Pvt Ltd reserves the right to investigate and disqualify any fraudulent or suspicious referral activity. Users found to be engaging in fraudulent behavior, including but not limited to creating multiple accounts or using fake referrals, will be ineligible for rewards and may face account suspension or termination.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">3.4 Modification of Policy:</h3>
        <p className="mb-4">Maya Multipurpose Pvt Ltd reserves the right to modify, update, or revise this Refer and Earn Policy at any time without prior notice. Users will be notified of any changes, and continued participation in the referral programs after such modifications constitutes acceptance of the revised terms.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Contact Information</h2>
        <p className="mb-4">For inquiries or assistance regarding the Refer and Earn programs, please contact Maya customer support at support@mayanp.com.</p>

        <p className="mt-8">This Refer and Earn Policy outlines the terms and conditions for participating in the Normal Refer and Diamond Refer programs within the Maya application. Should you have any questions or concerns regarding these programs or this Policy, please do not hesitate to contact us.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ReferAndEarnPolicy;
