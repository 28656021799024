
import React, {  useState } from 'react';
import Hotelimage from '../image/hotel.jpeg'
import Combined from '../image/combined-removebg.webp'
import courierimage from "../image/mayacourier.png"
import Restaurantimage from '../image/restaurant.jpg'
import BusImage from '../image/largebus-removebg.webp'
import ScrollReveal from './ScrollReveal';
function Partners() {
    const partners = [
        { title: "Ride Partner", subtitle: "Ride & Earn", description: "Partnering with Maya as a ride provider allows you to offer taxi or motorcycle services through their user-friendly app. This partnership not only increases your visibility and access to a broad customer base but also enhances your earning potential by connecting you with passengers seeking reliable transportation options. As a ride partner, you'll benefit from flexible scheduling, efficient route management, and the support of Maya's technology-driven platform, which ensures seamless transactions and customer satisfaction.", image1: Combined },
        { title: "Delivery Partner", subtitle: "Delivery & Earn", description: "Become a delivery partner with Maya for both food and courier services. Joining is easy through the app, where you can set up your profile and start receiving delivery requests promptly. Whether you're delivering meals or packages, Maya provides flexibility in scheduling and transparent earnings. It's a great way to earn on your own terms while contributing to efficient service delivery in your community. Join Maya today and begin earning as a reliable delivery partner.", image1: courierimage },
        { title: "Restaurant Partner", subtitle: "Become a Restaurant partner & Earn", description: "By becoming a Maya restaurants partner, you can expand your reach and increase your revenue streams through their food delivery service. Maya connects your restaurant with a vast network of customers who are eager to explore a variety of cuisines conveniently delivered to their doorstep. This partnership not only boosts your order volume but also streamlines operations with integrated order management and delivery logistics. Maya's promotional initiatives and marketing strategies further enhance your visibility and attract new patrons, making it a lucrative collaboration for restaurant owners aiming to grow their business.", image1: Restaurantimage },
        { title: "Hotel Partner", subtitle: "Become a Hotel partner & Earn", description: "Partnering with Maya as a hotel provider enables you to showcase your accommodations to travelers seeking comfortable and convenient stays. Whether you operate a boutique hotel or a chain of luxury resorts, Maya's platform allows you to efficiently manage bookings, room availability, and guest preferences. This partnership not only increases your occupancy rates but also enhances guest satisfaction with streamlined booking processes and real-time updates. Maya's commitment to technology integration and customer service ensures that your hotel maintains a competitive edge in the hospitality industry, attracting discerning travelers and maximizing revenue opportunities.", image1: Hotelimage },
        { title: "Bus Partner", subtitle: "Become a Bus partner & Earn", description: "Joining Maya as a bus partner allows you to offer comprehensive ticketing services for various routes and destinations, catering to travelers' diverse transportation needs. Maya's platform simplifies the booking experience for passengers, providing them with access to reliable schedules, fare information, and seat reservations. As a bus partner, you benefit from increased ticket sales facilitated by Maya's extensive user base and intuitive booking system. Real-time updates on route changes, availability, and travel advisories further enhance customer satisfaction and loyalty, positioning your bus services as a preferred choice among travelers.", image1: BusImage },
    ];

    const [selectedPartner, setSelectedPartner] = useState(partners[0]);


    return (
        <div id="partners" className=" lg:max-h-[760px]  bg-[#F4EFED] border-b-2  md:py-8 ">
            <div className="text-center pt-4 pb-8">
                <h1 className="  text-3xl md:text-4xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">Earn with Maya</h1>
            </div>
            <div className="flex overflow-x-auto ml-4 justify-start lg:justify-center md:flex-row md:flex-nowrap md:gap-4">
                {partners.map((partner, index) => (
                    <div
                        key={index}
                        onClick={() => setSelectedPartner(partner)}
                        className={` text-white shadow-md py-2  rounded-2xl text-center hover:bg-rose-600 mx-2 mb-4 cursor-pointer inline-flex items-center justify-center px-4  ${selectedPartner.title === partner.title ? 'bg-rose-600 border-2 border-gray-300' : 'bg-black'}`}
                        
                    >
                        <h2 className="whitespace-nowrap text-md md:text-lg font-semibold font-PPAgrandir">{partner.title}</h2>
                    </div>
                ))}
            
            </div>


            <div className='md:flex md:justify-center  my-2 px-4 sm:px-6 lg:px-12'>
                {selectedPartner && (
                    <>
                        <div className=" mt-8 p-1 md:p-4 w-full md:w-[80%] lg:w-[55%] bg-[rgba(6,26,55,.04)] shadow-lg rounded-2xl lg:rounded-bl-2xl lg:rounded-tl-2xl lg:max-w-2xl lg:rounded-tr-none lg:rounded-br-none border-2 border-gray-300 ">
                            <h2 className=" text-xl md:text-2xl pl-4 font-semibold text-black  mb-4 font-PPAgrandir">{selectedPartner.subtitle}</h2>
                            <div className=" lg:hidden mt-8 w-full flex justify-center mb-4 md:mb-6" >

                                <div className="w-[70%] md:w-full  rounded-large">
                                    <img
                                        src={selectedPartner.image1}
                                        alt="partners"
                                        className="w-full h-full object-cover rounded-large"
                                    />
                                </div>

                            </div>
                            <div className='text-justify pb-4 ml-2'>
                                <p className='text-gray-700 text-sm md:text-lg mx-2 md:mx-4 lg:text-lg '>{selectedPartner.description}</p>
                            </div>
                            {/* <div className="flex justify-center mt-4 md:block">
                            <button className="bg-[#000033] text-white ml-2 my-2 px-2 md:py-1 rounded-lg hover:bg-red-700 transition duration-300 flex items-center group">
                                
                                <div className=" p-2">
                                    <FontAwesomeIcon icon={faGooglePlay} className="text-white text-3xl  transition duration-300" />
                                </div>
                                <div className="ml-1 flex mx-2">
                                    <span className="text-md font-bold pr-1">Download app</span>
                                    <span className="hidden md:group-hover:block font-bold text-md">for become a partner</span>
                                </div>
                            </button>
                            </div> */}
                        </div>
                        <div className=" hidden lg:mt-8  w-full lg:w-[45%]  lg:flex justify-center items-center rounded-tr-2xl rounded-br-2xl bg-[rgba(6,26,55,.04)] shadow-lg border-2 border-gray-300">

                            <div className="  w-full lg:w-[90%]  lg:flex lg:items-center  xl:w-[82%]  xl:h-[93%]   rounded-large">
                                <ScrollReveal>
                                <img src={selectedPartner.image1} alt="partners" className="w-full h-full  lg:my-4 object-contain xl:object-cover rounded-large  " />
                                </ScrollReveal>
                            </div>

                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Partners;
