import React, { useEffect, useState } from 'react';
import Logo from '../image/maya-logo.webp'
import { Link, useNavigate } from 'react-router-dom';
function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {


    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const navigate = useNavigate();
  return (
    <nav className={`w-full font-poppins text-white px-6 md:px-2 py-1 ${isOpen ? 'bg-[#F4EFED]  z-20 h-screen fixed ' : ''}`}>
      <div className=" flex justify-between lg:justify-around items-center pt-2 md:pt-3">
        <div className=" flex justify-start md:ml-8  lg:w-1/6">
          <a href="/" >
            <img src={Logo} alt="logo" className='w-16 h-16 ' id='logo' />
          </a>
        </div>
        <div className=" lg:w-4/6 list hidden md:flex md:justify-between">
          <ul itemScope itemType="https://schema.org/SiteNavigationElement" className="flex md:space-x-4 lg:space-x-6 md:text-md font-semibold text-black ">
            <li className='  py-1'>
              <Link
                to="/"
                className=" mx-2 lg:mx-4 transition duration-300 hover:text-rose-600"
                itemProp="url"
              >
                <span itemProp="name">Home</span>
              </Link>
            </li>
            <li itemScope itemType="https://www.schema.org/SiteNavigationElement" className=' py-1'>
              <Link
                to="/about"
                className=" mx-2 lg:mx-4 transition duration-300 hover:text-rose-600"
                itemProp="url"
              >
                <span itemProp="name">About</span>
              </Link>

            </li>
            <li itemScope itemType="https://www.schema.org/SiteNavigationElement" className='  py-1'>
                <Link
                to="/services"
                className=" mx-2 lg:mx-4 transition duration-300 hover:text-rose-600"
                itemProp="url"
              >
                <span itemProp="name">Services</span>
              </Link>
            </li>
            <li itemScope itemType="https://www.schema.org/SiteNavigationElement" className='  py-1'>
                <Link
                to="/partner"
                className=" mx-2 lg:mx-4 transition duration-300 hover:text-rose-600"
                itemProp="url"
              >
                <span itemProp="name">Partner</span>
              </Link>
            </li>
            <li itemScope itemType="https://www.schema.org/SiteNavigationElement" className=' py-1'>
                <Link
                to="/help"
                className=" mx-2 lg:mx-4 transition duration-300 hover:text-rose-600"
                itemProp="url"
              >
                <span itemProp="name">Help</span>
              </Link>
            </li>

          </ul>
        </div>
        <div className="  lg:w-1/6  lg:-ml-4 downloadbutton hidden md:flex justify-start ">
          {/* <button className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-[#000033] transition duration-300">
            Download app
          </button> */}
          <button className="bg-white text-black font-semibold md:text-md md:px-2  py-1  rounded-lg hover:bg-rose-600 hover:text-white transition duration-300 " onClick={() => navigate('/downloadapp')}>
            Download app
          </button>
        </div>
        <div className="md:hidden flex justify-start items-center ">
          <button onClick={() => setIsOpen(!isOpen)} className="outline-none mobile-menu-button">
            <div className='border border-spacing-1 p-1 border-rose-500'>
              <svg
                className="w-6 h-6 text-rose-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path d="M6 18L18 6M6 6l12 12"></path>
                ) : (
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                )}
              </svg>
            </div>

          </button>
        </div>
      </div>

      <div className={`md:hidden ${isOpen ? 'block animate-slideIn' : 'hidden'}`}>
        <ul itemScope itemType="https://www.schema.org/SiteNavigationElement" className="flex flex-col items-center ">
          {["Home", "About", "Services", "Partner", "Help"].map((item, index) => (
            <div key={index} className='w-full text-center flex justify-center'>


              <li itemScope itemType="https://www.schema.org/SiteNavigationElement" className='w-24 my-4'>
                <Link
                  to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                  onClick={() => setIsOpen(!isOpen)}
                  // className="block bg-black rounded-lg hover:bg-rose-500 transition duration-300 py-2 w-11/12 mx-auto"
                  className='block text-black rounded-lg hover:text-rose-500 transition duration-300 py-2 w-11/12 mx-auto font-semibold'
                  itemProp="url" >
                  <span itemProp="name">{item}</span>
                </Link>
              </li>
            </div>
          ))}
          <li className='w-full text-center flex justify-center my-4'>
            <button
              className="w-8/12 bg-white text-black font-semibold text-md px-4 py-2 rounded-lg shadow-md hover:bg-rose-500 hover:text-white transition duration-300"
              onClick={() => {
                navigate('/downloadapp');
                setIsOpen(!isOpen);
              }}
            >
              Download App
            </button>
          </li>
        </ul>
      </div>



    </nav>
  );
}

export default Navbar;
