import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
function TermsOfServiceBusPartner() {
  return (
    <div className='bg-[#F4EFED]'>
      <Navbar />

      <div className="bg-[#F4EFED] font-poppins text-gray-700 py-8 px-8 md:p-16  ">
        <nav className="mb-4  text-xs md:text-sm">
          <Link to="/terms-and-condition" className="text-[#D60620]">Categories</Link>
          <span className="mx-2"><FontAwesomeIcon icon={faChevronRight} /></span>
          <span className="text-[#D60620]">Terms of service agreement for bus partner</span>
        </nav>
        <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-black font-PPAgrandir">Terms of Service Agreement</h1>
        <div className='text-justify'>
          <p className="mb-4">Maya Multipurpose Pvt Ltd</p>
          <p className="mb-4">Application: Maya</p>
          <p className="mb-4">This Terms of Service Agreement ("Agreement") constitutes a legally binding contract between Maya Multipurpose Pvt Ltd ("Maya"), a reputable company registered in Nepal, and you, an esteemed bus partner ("Partner"). This Agreement delineates the terms and conditions governing your esteemed collaboration with Maya and the provision of bus transportation services through the Maya application ("App").</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
          <p className="mb-4">By accessing or utilizing the Maya App as a bus partner, you affirmatively acknowledge and accept all the terms and conditions stipulated in this Agreement. Your continued engagement with Maya signifies your assent to be bound by the provisions outlined herein.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Description of Services</h2>
          <p className="mb-4">As an esteemed bus partner with Maya, you have the esteemed opportunity to provide reliable and comfortable transportation services to passengers through the Maya platform. Your services encompass, but are not limited to:</p>
          <ul className="list-disc list-inside mb-4">
            <li>Operating bus routes and schedules as designated by Maya.</li>
            <li>Ensuring the safety, cleanliness, and comfort of passengers during their journey.</li>
            <li>Collaborating with Maya to expand your customer base, increase occupancy rates, and maximize revenue opportunities.</li>
          </ul>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Registration and Onboarding</h2>
          <p className="mb-4">To commence your esteemed partnership with Maya, you must complete the registration and onboarding process as outlined by Maya Multipurpose Pvt Ltd. This process may entail providing requisite documentation, agreeing to partnership terms, and undergoing training on the utilization of the Maya platform for managing bus routes and passenger bookings.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Route Management</h2>
          <p className="mb-4">As a esteemed bus partner, you are responsible for managing your bus routes and schedules on the Maya platform. This encompasses updating route details, departure times, ticket prices, and any special offers or promotions to ensure accurate representation to passengers.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Ticket Booking and Passenger Interaction</h2>
          <p className="mb-4">Upon receiving bookings through the Maya platform, you are responsible for promptly confirming reservations and facilitating boarding for passengers. Providing timely assistance to passengers, addressing inquiries, and ensuring a smooth boarding process is essential for fostering passenger satisfaction and loyalty.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Safety and Compliance</h2>
          <p className="mb-4">Maintaining high standards of safety, compliance, and regulatory adherence is imperative. As a bus partner, you agree to adhere to all applicable transportation regulations, safety protocols, and passenger welfare standards to ensure the safety and well-being of passengers during their journey.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">7. Pricing and Payment</h2>
          <p className="mb-4">You have the discretion to set ticket prices and pricing policies for your bus routes on the Maya platform. Maya Multipurpose Pvt Ltd will facilitate payment processing and transfer the designated portion of the ticket revenue to you, minus any applicable fees or commissions as per the agreed-upon terms.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">8. Passenger Privacy and Security</h2>
          <p className="mb-4">Maya respects the privacy and security of passenger information and data. Any information shared or collected during passenger interactions and bookings will be handled in accordance with Maya's Privacy Policy and applicable data protection laws.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">9. Brand Representation</h2>
          <p className="mb-4">As an esteemed partner, you agree to represent your bus service professionally and uphold its reputation while collaborating with Maya. This includes maintaining consistent branding, service standards, and passenger experiences to enhance your bus service's reputation and passenger satisfaction.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">10. Marketing and Promotion</h2>
          <p className="mb-4">Maya may engage in marketing and promotional activities to promote your bus service and routes to potential passengers. By partnering with Maya, you grant permission for the use of your bus service's name, logo, and images for promotional purposes.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">11. Modification of Terms</h2>
          <p className="mb-4">Maya Multipurpose Pvt Ltd reserves the right to modify, update, or revise the terms of this Agreement at any time. You will be notified of any changes, and continued partnership with Maya after such modifications constitutes acceptance of the revised terms.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">12. Termination of Partnership</h2>
          <p className="mb-4">Maya reserves the right to terminate your partnership if you breach any terms of this Agreement, fail to meet performance standards, or engage in any conduct deemed detrimental to Maya or its passengers. Termination may result in the removal of your bus routes from the Maya platform.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">13. Dispute Resolution</h2>
          <p className="mb-4">In the event of any disputes or disagreements arising under this Agreement, both parties agree to engage in good faith negotiations to resolve the matter amicably. If a resolution cannot be reached, disputes may be subject to arbitration as per the laws of Nepal.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">14. Indemnification</h2>
          <p className="mb-4">You agree to indemnify and hold Maya Multipurpose Pvt Ltd harmless from any claims, damages, losses, or liabilities arising from your partnership with Maya or any breach of this Agreement.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">15. Governing Law and Jurisdiction</h2>
          <p className="mb-4">This Agreement shall be governed by and construed in accordance with the laws of Nepal. Any legal proceedings arising under this Agreement shall be subject to the exclusive jurisdiction of the courts of Nepal.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">16. Entire Agreement</h2>
          <p className="mb-4">This Agreement constitutes the entire understanding between you and Maya Multipurpose Pvt Ltd regarding your partnership and supersedes any prior agreements or understandings, whether written or oral.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">17. Survival</h2>
          <p className="mb-4">Sections of this Agreement relating to indemnification, dispute resolution, and termination shall survive the termination of your partnership with Maya.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">18. Contact Information</h2>
          <p className="mb-4">For inquiries or assistance regarding this Agreement, please contact Maya Multipurpose Pvt Ltd at <a href="mailto:support@mayanp.com" className="text-blue-600">support@mayanp.com</a>.</p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">19. Effective Date</h2>
          <p className="mb-4">This Agreement shall become effective upon your acceptance of its terms and conditions.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfServiceBusPartner;
