import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import NavbarHome from './NavbarHome';
function Home() {
  const [isSmallScreenHeight, setIsSmallScreenHeight] = useState(false);

  useEffect(() => {
    const checkScreenHeight = () => {
      if (window.innerHeight < 620) {
        setIsSmallScreenHeight(true);
      } else {
        setIsSmallScreenHeight(false);
      }
    };

    // Check screen height on initial render
    checkScreenHeight();

    // Add event listener to handle window resize
    window.addEventListener('resize', checkScreenHeight);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenHeight);
    };
  }, []);
  return (
    <div className="text-white bg-homepage  h-screen  md:max-h-[730px] xl:max-h-screen items-center justify-center w-full ">
      <div className='h-full w-full bg-black bg-opacity-30'>
        <NavbarHome />
        <div className="md:flex justify-start items-center w-full h-[90%]  px-4 md:mx-0">
          <div className=' w-full md:w-1/2  lg:w-[48%] h-full flex items-center'>
            <div className="  md:mx-4 md:text-left  md:mt-0 ">
              <h1 className="px-2  text-5xl md:text-7xl font-Bugaki  animate-slideIn">MAYA</h1>
              <h2 className="px-2 text-3xl md:text-4xl mb-2 font-Bugaki animate-slideIn">The Super App</h2>
              <p  className={`text-base lg:text-lg mb-2 px-2 rounded-2xl animate-slideIn text-justify bg-black bg-opacity-15 
      ${isSmallScreenHeight ? 'line-clamp-7' : 'line-clamp-12'} 
      md:line-clamp-6 lg:line-clamp-7 xl:line-clamp-8`}>
                "Maya is a comprehensive mobile application offering a wide range of services tailored for everyday convenience. Users can seamlessly access ride sharing, motorcycle rides, food ordering, courier delivery, hotel booking, bus ticketing, and flight booking—all within a single, intuitive platform. Whether you need to get somewhere quickly, order food, send packages, or plan your travel accommodations, Maya simplifies the process with a user-friendly interface and robust service options. Download Maya today to experience effortless mobility and efficient service management."
              </p>
              <a href='/about'>
                <button className="  py-1 px-2 md:px-4 md:py-2 mb-4 md:mb-0 rounded-3xl  transition duration-300 bg-white text-black font-semibold text-base lg:text-xl  hover:bg-rose-600  hover:text-white animate-slideIn">
                  View More
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </a>
            </div>
          </div>
        </div> 

      </div>
    </div>
  );
}

export default Home;
