import Footer from "./Footer";
import Navbar from "./Navbar";
import BlogImage from "../image/blog.png";
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
const blogData = [
    {
      "title": "Navigate Your City with Ease: Maya's Ride-Sharing Solutions",
      "slug": "navigate-your-city-with-ease",
      "introduction": "Navigating through city traffic can be daunting, but Maya is here to simplify your commute with our reliable ride-sharing solutions. Whether you prefer the comfort of a taxi or the agility of a motorcycle, Maya connects you with trusted drivers who ensure safe and efficient journeys.",
      "content": {
        "Why Choose Maya for Ride-Sharing?": [
          "Explore the convenience of booking rides seamlessly through our user-friendly app.",
          "Enjoy competitive pricing and transparent fare estimates before confirming your ride.",
          "Access a network of reliable drivers who prioritize your safety and comfort."
        ],
        "Diverse Options: Taxis and Motorcycles": [
          "Discover the flexibility to choose between taxis for group travel or motorcycles for swift solo rides.",
          "Experience the agility and convenience of motorcycle rides, perfect for navigating busy city streets."
        ],
        "Customer Testimonials and Success Stories": [
          "Hear from satisfied customers who have benefited from Maya’s ride-sharing services.",
          "Learn how Maya has transformed their daily commute, saving time and reducing stress."
        ]
      },
      "conclusion": "Join Maya today and experience hassle-free ride-sharing tailored to meet your urban transportation needs. Whether you’re heading to work, exploring the city, or meeting friends, Maya ensures a smooth journey from start to finish."
    },
    {
      "title": "Savor Every Bite: Maya’s Food Delivery Experience",
      "slug": "savor-every-bite",
      "introduction": "Craving your favorite cuisine but don’t feel like dining out? Maya brings the culinary delights of your city straight to your doorstep with our efficient food delivery service. From local favorites to international flavors, indulge in a diverse range of dishes without leaving the comfort of your home.",
      "content": {
        "Wide Selection of Restaurants": [
          "Explore a curated selection of restaurants offering various cuisines through the Maya app.",
          "Browse menus and discover new dining experiences conveniently from your smartphone."
        ],
        "Efficient Delivery": [
          "Enjoy prompt and reliable delivery services that ensure your food arrives fresh and hot.",
          "Track your order in real-time and receive updates on its status from preparation to delivery."
        ],
        "Special Features and Promotions": [
          "Stay updated on exclusive offers, discounts, and seasonal promotions available through Maya.",
          "Benefit from loyalty programs and rewards that enhance your dining experience."
        ],
        "Customer Testimonials and Success Stories": [
          "Read stories from satisfied customers who have savored memorable meals delivered by Maya.",
          "Learn how Maya has simplified their dining choices and brought convenience to their doorstep."
        ]
      },
      "conclusion": "Whether it’s a quick lunch, a family dinner, or a late-night snack, Maya’s food delivery service ensures you enjoy delicious meals with ease. Discover new culinary delights and savor every bite from the comfort of your home."
    },
    {
      "title": "Plan Your Perfect Getaway: Maya’s Hotel Booking and Travel Solutions",
      "slug": "plan-your-perfect-getaway",
      "introduction": "Dreaming of a getaway? Maya makes travel planning effortless with our comprehensive hotel booking and travel solutions. Whether you’re traveling for business or leisure, explore a variety of accommodations and book your stay with ease.",
      "content": {
        "Explore and Book Accommodations": [
          "Browse through a wide range of hotels, resorts, and lodges available through Maya’s platform.",
          "Filter search results based on location, budget, amenities, and guest reviews to find your ideal stay."
        ],
        "Travel Convenience": [
          "Plan your entire trip seamlessly by combining hotel bookings with transportation options available through Maya.",
          "Access special deals and packages that enhance your travel experience and save you money."
        ],
        "Travel Tips and Destination Insights": [
          "Discover travel tips, destination guides, and local insights to make the most of your trip.",
          "Learn about hidden gems and must-visit attractions in popular travel destinations."
        ],
        "Customer Testimonials and Success Stories": [
          "Hear from travelers who have booked their accommodations and planned their trips using Maya.",
          "Learn how Maya’s travel solutions have enhanced their travel experiences and provided peace of mind."
        ]
      },
      "conclusion": "Whether you’re embarking on a solo adventure, planning a family vacation, or traveling for work, Maya ensures your trip is memorable and stress-free. Start planning your perfect getaway with Maya today and unlock a world of travel possibilities."
    },
    {
      "title": "Maya Collaborates with Galaxy Hospital Pvt. Ltd. to Offer Health Discounts for Users and Partners",
      "slug": "maya-collaborates-with-galaxy-hospital",
      "introduction": "Maya is excited to announce a groundbreaking collaboration with Galaxy Hospital Pvt. Ltd. in Pokhara. This partnership is set to bring valuable health discounts to Maya users and our extensive network of partners, including ride-sharing services, bus owners, restaurant operators, and hotel staff. By joining forces with Galaxy Hospital, we aim to enhance the benefits and convenience of our all-encompassing app.",
      "content": {
        "About Maya": [
          "Maya is your go-to mobile application designed to simplify daily life with a wide range of services. From booking rides and ordering food to arranging courier deliveries, hotel stays, and bus tickets, Maya integrates all these essential services into one user-friendly platform. Our mission is to streamline your routine tasks and provide a seamless experience."
        ],
        "Introducing Galaxy Hospital Pvt. Ltd.": [
          "Galaxy Hospital Pvt. Ltd., located in the beautiful city of Pokhara, is recognized for its advanced medical technology and exceptional patient care. The hospital offers comprehensive healthcare services, including emergency care, diagnostics, and specialized treatments, making it a leading healthcare provider in the region."
        ],
        "What This Collaboration Means for You": [
          "Exclusive Health Discounts for Maya Users: As part of this collaboration, Maya users can enjoy special health discounts at Galaxy Hospital. Whether you need a routine check-up or specialized medical treatment, you’ll benefit from reduced rates and exclusive offers.",
          "Enhanced Benefits for Maya Partners: Our network of partners—such as ride-sharing drivers, bus owners, restaurant operators, and hotel staff—will also receive health discounts at Galaxy Hospital. This initiative is designed to support those who contribute to our ecosystem by providing them with valuable health benefits.",
          "Unified Service Experience: By integrating Galaxy Hospital’s services with the Maya app, managing your healthcare needs becomes as straightforward as using any other Maya feature. From scheduling appointments to accessing discounts, everything is handled seamlessly through the app.",
          "Increased Value for Everyone: This collaboration not only adds value for individual users but also for our entire partner network. We aim to foster a healthier and more supported community, enhancing the overall experience for all involved."
        ],
        "How It Works": [
          "To access your health discounts, follow these simple steps:",
          [
          "Visit Galaxy Hospital Pvt. Ltd.: Go to Galaxy Hospital in Pokhara with your Maya app in hand.",
          "Verify Your Status: Inform the hospital staff that you are a user or partner of the Maya app.",
          "Show Your Maya App: Present your Maya app to the hospital team to confirm your eligibility for the discounts.",
          "Enjoy the Benefits: Once verified, you’ll receive the exclusive health discounts available through this collaboration.",
          ],
          "For partners, including ride-sharing drivers, bus owners, restaurant operators, and hotel staff, the process is the same. Visit Galaxy Hospital, mention your association with Maya, and show the app to access your special health benefits."
        ],
        "Looking Forward": [
          "This collaboration is a significant step towards integrating health services with everyday conveniences. We are excited about the potential for future innovations and improvements that will further enhance your experience with Maya and Galaxy Hospital."
        ]
      },
      "conclusion": "Maya’s collaboration with Galaxy Hospital Pvt. Ltd. is a major advancement in providing integrated health services and benefits. By offering exclusive health discounts to both users and partners, we are elevating the convenience and value of the Maya platform. We look forward to continuing to support your health and overall well-being with this exciting new initiative."
    }
  ]
  
function BlogPage() {
    const navigate = useNavigate();

    const handleReadMore = (blog) => {
        navigate(`/blogs/${blog.slug}`, { state: { blog} });
    };
    return (
        <div className=' bg-[#F4EFED] w-full font-poppins'>
            <Helmet>
                <title>Blogs - Maya-The Super App</title>
                <meta name='description' content='' />
                <link rel="canonical" href="https://mayanp.com/blogs" />
            </Helmet>
            <Navbar />
            <div className="w-full my-8 font-poppins">
            <div className="text-center ">      
                <h1 className=" text-3xl md:text-4xl pb-2 font-semibold text-black inline-block md:pb-4 font-PPAgrandir">Our Blogs</h1>
        </div>
                <div className="flex justify-center py-4 mx-2 md:mx-4 ">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 space-x-4  w-[90%]"
                    >
                        {blogData.map((blog, index) => (
                            <div key={index} className="border bg-gray-50 rounded-3xl shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 my-4 sm:mx-2 mx-auto">
                                <div className="w-full mb-4">
                                    <img src={BlogImage} alt="Blog" className="w-full object-cover rounded-t-3xl  transition-opacity duration-300"  loading="lazy"/>
                                </div>
                                <div className="text-start text-base md:text-lg font-semibold ml-4 ">
                                    <p className="">{blog.title}</p>
                                </div>
                                <div className="p-4">
                                    <p className="text-black text-opacity-70 font-serif text-sm md:text-base leading-relaxed line-clamp-4">{blog.introduction}</p>
                                </div>
                                <div className="mb-4 ml-4">
                                {/* <a href='/blogdescription'> */}
                                <button onClick={() => handleReadMore(blog)}
                                    className="py-1 px-2 md:px-4 md:py-2 mb-4 rounded-lg transition duration-300 bg-gray-200 text-black font-semibold text-sm hover:bg-rose-400 hover:text-white">
                                        Read more
                                    </button>
                                    {/* </a> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default BlogPage;