import React from 'react';
import Service from './Service';
import Hotelimage from '../image/hotelimage-removebg.webp'
import Combined from "../image/combined-removebg.webp"
import courierimage from "../image/mayacourier.png"
import Flightimage from "../image/flightimage-removebg.webp"
import BusImage from '../image/largebus-removebg.webp'
import food from "../image/food.webp"
const services = [
  { title: "Ride-Sharing (Taxis & Motorcycles)", description: "Maya offers convenient ride-sharing with taxis and motorcycles, ensuring reliable urban transportation. Enjoy transparent pricing and safety-focused drivers for a seamless commute.",  image1: Combined },
  { title: "Food Delivery", description: "Explore diverse cuisines delivered swiftly to your doorstep. Maya's food delivery service lets you browse menus, place orders easily, and track deliveries in real-time for a delightful dining experience.", image1: food },
  { title: "Courier Delivery", description: "Send and receive parcels with Maya's efficient courier service. Schedule pickups, track deliveries, and rest assured knowing your items are handled securely and delivered on time.", image1: courierimage  },
  { title: "Bus Ticketing", description: "Simplify travel planning with Maya's bus ticketing. Browse schedules, book seats, and travel seamlessly across destinations, whether for daily commutes or long-distance trips."

, image1: BusImage },
{ title: "Hotel Booking", description: "Plan your stay effortlessly with Maya's hotel booking service. From budget-friendly to luxury accommodations, find and book your perfect hotel with ease through the app." , image1: Hotelimage },
  { title: "Flight Booking", description: "Simplify air travel arrangements with Maya's flight ticketing service. Browse flight options, compare prices, and book tickets conveniently through the app, ensuring smooth journeys to your desired destinations." , image1:Flightimage },
];


const Services = () => {
  return (
    <section id="services" className="py-8 md:min-h-screen lg:min-h-[660px] bg-[#F4EFED]  ">
      <div className="text-center pt-4 pb-8">      
                <h1 className=" text-3xl md:text-4xl pb-2 font-semibold text-black inline-block md:pb-4 font-PPAgrandir">Our services</h1>
        </div>
      <div className=" w-full flex justify-center sm:px-2 md:px-2">
        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-6 md:my-4 mx-4 md:w-[90%]">
          {services.map((service, index) => (
            <Service key={index} service={service} />
          ))}
        </div>
      </div>
    </section>

  );
};

export default Services;
