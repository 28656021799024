import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
function FAQ({faqs}) {
    const [activeIndex, setActiveIndex] = useState(null);

  
    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div className='w-full  px-4 pt-8 font-poppins'>

            {faqs.map((faq, index) => (
                <div key={index} className='mb-4'>
                    <button
                        onClick={() => toggleFAQ(index)}
                        className='w-full text-left p-2 bg-white border-2 border-rose-50 rounded-lg shadow-sm focus:outline-none'
                    >
                        <div className='flex justify-between items-center'>
                            <span className='text-base md:text-xl text-bold'>{faq.question}</span>
                            <span>{activeIndex === index ? <FontAwesomeIcon icon={faAngleUp}/> :  <FontAwesomeIcon icon={faAngleDown}/>}</span>
                            
                            {/* <img src={activeIndex === index ?'https://myatthihanaing-mthn.github.io/react-faq/icon-minus.svg':'https://myatthihanaing-mthn.github.io/react-faq/icon-plus.svg' } alt="faq-button" className="xs: w-6 ml-4 cursor-pointer"/> */}
                        </div>
                        {activeIndex === index && (
                        <div className='p-4 bg-gray-50 text-black text-opacity-70  rounded-b-lg'>
                            <p className="text-sm md:text-base">{faq.answer}</p>
                        </div>
                    )}
                    </button>
                    
                </div>
            ))}
        </div>
    );
}
export default FAQ;