import Footer from "./Footer";
import Navbar from "./Navbar";
import Download from "./Download";
import { Helmet } from "react-helmet-async";
function DownloadPage() {
  return (
    <div className=' w-full font-poppins'>
      <Helmet>
        <title>Download - Maya-The Super App</title>
        <meta name='description' content='Download MAYA app now to experience seamless access to our services!' />
        <link rel="canonical" href="https://mayanp.com/download" />
      </Helmet>
      <Navbar />
      <Download />
      <Footer />
    </div>
  );
}

export default DownloadPage;