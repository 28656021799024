
import Mobiledesign from '../image/mobiledesign.webp'
import GooglePlay from '../image/googleplay.png'
import Applestore from '../image/applestore.png'
import ScrollReveal from "./ScrollReveal";
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
function Download() {
  return (
    <div className="md:h-screen md:max-h-[730px]  md:flex border-b-2  bg-white justify-center items-center">
      <div className=" w-full md:w-1/2 md:pl-10 pl-4 mt-8 md:mt-0">
        <div className="text-center md:text-start  px-4">
          <h1 className="text-3xl md:text-4xl pb-2 mt-4 font-semibold text-black md:pb-4 font-PPAgrandir">
            Download Now
          </h1>
        </div>

        <div className="w-full md:w-full flex justify-start p-4">
          <div className="text-start space-y-6">
            <h2 className="text-black text-base  md:ml-4 md:text-lg lg:text-xl w-[80%]">
              Download MAYA app now to experience seamless access to our services!
            </h2>
            <div className=''>
              <h2 className="text-black text-xl font-semibold md:text-2xl font-PPAgrandir">For Customer</h2>
              <div className="flex justify-start md:mt-4 ml-4">
                <div className="md:flex md:space-x-4 mt-4">
                  <img src={GooglePlay} alt="googleplay" className="h-12  lg:h-16 xl:h-20 mb-4" id="customer-google-tooltip" />
                  <img src={Applestore} alt="applestore" className=" h-12 lg:h-16 xl:h-20" id="customer-apple-tooltip" />
                </div>
              </div>
            </div>
            <div className=''>
              <h2 className="text-black text-xl font-semibold md:text-2xl font-PPAgrandir">For Partner's</h2>
              <div className="flex justify-start md:mt-4 ml-4">
                <div className="md:flex md:space-x-4 mt-4">
                  <Link to='https://play.google.com/store/apps/details?id=com.maya.rider' target="_blank" rel="noopener noreferrer">
                    <img src={GooglePlay} alt="googleplay" className="h-12  lg:h-16 xl:h-20 mb-4" />
                  </Link>
                  <Link to='https://apps.apple.com/us/app/maya-partners/id6453604723' target="_blank" rel="noopener noreferrer">
                    <img src={Applestore} alt="applestore" className=" h-12 lg:h-16 xl:h-20" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2">
        <div className=" w-full flex justify-start p-4">
          <ScrollReveal>
            <img
              src={Mobiledesign}
              alt="app" id='primaryImage'
              className="w-full h-auto max-w-lg object-cover rounded-3xl transform hover:scale-105 transition duration-300"
              loading="lazy" />
          </ScrollReveal>
        </div>
        <Tooltip
  anchorId="customer-google-tooltip"
  content="Coming Soon!"
  place="top"
  style={{
    backgroundColor: '#0C1844',  // Dark blue background
    color: '#FFF5E1',            // Light cream text color
    borderRadius: '8px',         // Rounded corners
    padding: '6px',             // Add padding for a spacious feel
    fontFamily: 'Poppins, sans-serif', // Custom font for consistency
    fontSize: '14px',            // Slightly larger font size for readability
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',  // Add shadow for depth
    transition: 'opacity 0.3s ease-in-out',  // Smooth fade animation
  }}
/>
<Tooltip
  anchorId="customer-apple-tooltip"
  content="Coming Soon!"
  place="top"
  style={{
    backgroundColor: '#0C1844',
    color: '#FFF5E1',            // Light cream text color
    borderRadius: '8px',
    padding: '6px',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    transition: 'opacity 0.3s ease-in-out',
  }}
/>

    
      </div>
    </div>
  );
}

export default Download;
