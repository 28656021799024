import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faXTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../image/maya-logo.webp'
import Phone from '../image/phone-call.png'
import Location from '../image/google-maps.png'
import Gmail from '../image/email.png'
import { Link } from "react-router-dom";
const Footer = () => {
    const form = useRef();
    const [loading, setLoading] = useState(false); // Add loading state
  
    const sendEmail = (e) => {
      e.preventDefault();
      setLoading(true);
      emailjs
        .sendForm('service_cvablay', 'template_lwd86ei', form.current, {
          publicKey: 'ar7m2B4_1vn5GTQUN',
        })
        .then(
          () => {
            toast.success('Email sent successfully!', );
            setLoading(false);
            setTimeout(() => {
              form.current.reset();
            }, 1000); 
          },
          (error) => {
            toast.error('Failed to send email. Please try again later.', 
            );
            setLoading(false);
            console.log('FAILED...', error.text);
          },
        );
    };
  
  return (
    <footer id="footer" className="bg-white py-8 text-black ">
      <div className="mx-4 md:px-2">
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          {/* Logo Section */}
          <div className="flex items-center mb-4 md:mb-0 lg:w-1/3 md:w-1/4">
            <div className="flex justify-start md:ml-4 lg:w-1/6">
            <a href="/" >
              <img src={Logo} alt="logo" className='w-16 h-16' />
              </a>
            </div>
          </div>

          {/* Navigation Links */}
          <div itemScope itemType="https://www.schema.org/SiteNavigationElement" className="hidden md:w-3/4 lg:w-2/3 md:flex justify-end md:mr-4 lg:mr-12 space-x-4 lg:space-x-6 text-sm md:text-md lg:text-lg font-semibold text-black">
            {["Home", "About", "Services", "Partner", "Help"].map((item, index) => (
              <Link
               itemProp="url"
                key={index}
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className=" rounded-lg px-4 py-2  lg:mx-4  transition duration-300 hover:text-[#D60620]"
              >
                 <span itemProp="name">{item}</span>
              </Link>
            ))}
          </div>
        </div>
        <ToastContainer />
        <div className="w-full md:flex justify-between ">
          {/* Left Section */}
          <div className="md:w-[60%] mb-8 md:mb-0 md:px-2 md:mt-4">
            <div className="mb-4">
              <h2 className="text-rose-600 text-3xl md:text-4xl lg:text-5xl font-bold mb-2 font-PPAgrandir">Connect with <br /> Maya </h2>
              <p className="text-[#0000FF] text-xl md:text-2xl lg:text-4xl font-semibold mb-2 font-PPAgrandir">for convenient travel solutions</p>
              <p className="pt-2 text-sm md:text-md lg:text-lg ml-2 md:max-w-2xl">Contact us for questions, technical assistance, or collaboration opportunities via the contact information provided.</p>
              <div className="mt-4 space-y-2 ml-4 text-sm md:text-base">
                <div className="flex items-center">
                  {/* <div className='w-8 h-8 bg-[#F4C430] rounded-full flex items-center justify-center border-2 border-rose-500 mr-4'>
                    <FontAwesomeIcon icon={faPhone} className="text-sm text-white" />
                  </div> */}
                     <img src={Phone} alt="phone" className='w-7 h-7 rounded-full flex items-center justify-center   mr-4' />
                  <p>061-590321</p>
                </div>
                <div className="flex items-center">
                  {/* <div className='w-8 h-8 bg-[#F4C430] rounded-full flex items-center justify-center border-2 border-rose-500 mr-4 '>
                    <FontAwesomeIcon icon={faEnvelope} className="text-sm w-full text-white" />
                  </div> */}
                  <img src={Gmail} alt="gmail" className='w-7 h-7 rounded-full flex items-center justify-center mr-4' />
                  <p className="break-words break-all">Mayamultipurpose.pvt.ltd@gmail.com</p>
                </div>
                <div className="flex items-center">
                  {/* <div className='w-8 h-8 bg-[#F4C430] rounded-full flex items-center justify-center border-2 border-rose-500 mr-4 '>
                    <FontAwesomeIcon icon={faLocationDot} className="text-sm text-white" />
                  </div> */}
                  <img src={Location} alt="location" className='w-7 h-7 rounded-full flex items-center justify-center   mr-4' />
                  <p>Pokhara-17, Birauta, Nepal</p>
                </div>
              </div>
            </div>
            <div className="">
              <p className="text-black text-lg md:text-xl lg:text-2xl mt-8 mb-4 mx-2">Get Social</p>
              <div className="md:flex md:justify-around lg:justify-between items-center xl:w-[80%]">
                <div className="flex space-x-4 mb-4 ml-4 lg:ml-2">
                  <FontAwesomeIcon icon={faFacebook} className="text-[#1877F2] text-lg md:text-lg lg:text-2xl hover:text-[#3b5998]" />
                  <FontAwesomeIcon icon={faInstagram} className="text-[#E4405F] text-lg md:text-lg lg:text-2xl hover:text-[#C13584]" />
                  <FontAwesomeIcon icon={faXTwitter} className="text-black text-lg md:text-lg lg:text-2xl hover:text-gray-950" />
                  <FontAwesomeIcon icon={faYoutube} className="text-[#FF0000] text-lg md:text-lg lg:text-2xl hover:text-[#CD201F]" />
                  <FontAwesomeIcon icon={faTiktok} className="text-black text-lg md:text-lg lg:text-2xl hover:text-gray-950" />
                </div>

                <div className="hidden md:flex space-x-2 ml-4 lg:space-x-6 lg:mr-4 md:ml-2 lg:ml-0">
                  <a href="/privacy" className="hover:underline  mb-2 text-sm text-gray-500">Privacy Policy</a>
                  <a href="/terms-and-condition" className="hover:underline  mb-0 text-sm text-gray-500">Terms & Conditions</a>
                </div>

              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="md:w-[40%] ml-4 md:ml-0 mb-2">
            <form className="space-y-4 w-[92%]" ref={form} onSubmit={sendEmail} >
              <div className="my-2 ">
                <label htmlFor="name" className="block mb-1 text-sm md:text-md lg:text-lg font-semibold md:font-bold">Name</label>
                <input type="text" id="name" name="name" placeholder="John Doe" className="w-full px-4 py-2 border-2 border-black rounded-lg" required/>
              </div>
              <div className="md:flex md:space-x-4 my-2">
                <div className="md:w-1/2">
                  <label htmlFor="email" className="block mb-1 text-sm md:text-md lg:text-lg font-semibold md:font-bold">Email</label>
                  <input type="email" id="email" name="email" placeholder="abc@gmail.com" className="w-full px-4 py-2 border-2 border-black rounded-lg" required/>
                </div>
                <div className="md:w-1/2">
                  <label htmlFor="number" className="block mb-1 text-sm md:text-md lg:text-lg font-semibold md:font-bold">Phone</label>
                  <input type="number" id="number" name="number" placeholder="9800000000" className="w-full px-4 py-2 border-2 border-black rounded-lg" required/>
                </div>
              </div>
              <div className="my-2">
                <label htmlFor="message" className="block mb-1 text-sm md:text-md lg:text-lg font-semibold md:font-bold">Message</label>
                <textarea id="message" name="message" placeholder="Your message..." className="w-full px-4 py-2 border-2 md:h-44 lg:h-44 border-black rounded-lg" rows="4" required></textarea>
              </div>
              {/* <button type="submit" value="Send" className="px-3 py-2 bg-[#F4C430] text-black rounded-lg hover:bg-rose-600 transition duration-300 font-semibold md:font-bold text-sm md:text-md lg:text-lg">SUBMIT NOW</button> */}
              <button type="submit" className="px-3 py-2 bg-[#F4C430] text-black rounded-lg hover:bg-rose-600 transition duration-300 font-semibold md:font-bold text-sm md:text-md lg:text-lg">
                {loading ? 'Submitting' : 'SUBMIT NOW'}
              </button>
            </form>
          </div>
        </div>
        <div className="md:hidden mt-4">
            <div className="flex justify-center space-x-4 ml-4  ">
              <a href="/privacy" className="hover:underline  mb-2 text-sm text-gray-500">Privacy Policy</a>
              <a href="/terms-and-condition" className="hover:underline  mb-0 text-sm text-gray-500">Terms & Conditions</a>
            </div>
        </div>
        <div className="text-center border-t-2 md:pt-2">
                <p className="text-sm text-gray-500">© 2024 Maya Multipurpose Private Limited. All Rights Reserved.</p>
              </div>
      </div>
    </footer>
  );
};

export default Footer;
