import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronRight } from "@fortawesome/free-solid-svg-icons";
function ReturnAndRefund() {
  return (
    <div className='bg-[#F4EFED]'>
      <Navbar />
      <div className=" text-gray-700 font-poppins py-4 px-4 md:px-8 text-justify">
      <nav className="mb-4 pt-8 text-xs md:text-sm">
                        <Link to="/privacy" className="text-[#D60620]">Categories</Link>
                        <span className="mx-2"><FontAwesomeIcon icon={faChevronRight}/></span>
                        <span className="text-[#D60620] ">Return and Refund Policy</span>
                    </nav>
                    <div className="text-center  pb-8">
          <h1 className=" text-3xl md:text-4xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">Return and Refund Policy</h1>
        </div>
<div className='mx-4'>
        <p className="mb-4">At Maya Multipurpose Pvt Ltd ("Maya"), we strive to provide our esteemed customers with a seamless and satisfying experience when utilizing our platform and availing our services. We understand that there may be instances where customers may need to return items or request refunds due to various reasons. This Return and Refund Policy outlines our procedures and guidelines regarding returns and refunds for services booked through the Maya application ("App").</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Return Policy</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.1 Ride Booking Services (Taxi & Bike):</h3>
        <p className="mb-4">Since ride bookings are typically instantaneous and non-refundable once confirmed, returns for ride bookings (taxi or bike rides) are generally not applicable. However, if there are extenuating circumstances or issues with the ride service provided (e.g., safety concerns, vehicle condition), customers are encouraged to contact Maya customer support for assistance.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.2 Delivery Services (Food & Courier):</h3>
        <p className="mb-4">For delivery services (food or courier), returns may be applicable in certain situations, such as:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Incorrect or incomplete delivery: If the delivered item does not match the order placed or is missing components, customers are eligible for a return.</li>
          <li>Quality issues: If the delivered food item is spoiled, damaged, or of unsatisfactory quality, customers may request a return.</li>
        </ul>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.3 Bus Ticketing, Hotel Booking, and Flight Booking Services:</h3>
        <p className="mb-4">Returns for bus tickets, hotel bookings, and flight bookings may be subject to the policies and terms of the respective service providers. Customers are advised to review the cancellation and refund policies applicable to their specific booking.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Refund Policy</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.1 Eligibility for Refunds:</h3>
        <p className="mb-4">Refunds are processed based on the eligibility criteria outlined in this policy and the specific circumstances of each case. Customers may be eligible for a refund under the following circumstances:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Ride Booking Services: Refunds may be issued for ride bookings in cases of service failures, safety concerns, or other extenuating circumstances.</li>
          <li>Delivery Services: Refunds may be issued for undelivered, incomplete, or unsatisfactory delivery services, subject to verification by Maya customer support.</li>
          <li>Bus Ticketing, Hotel Booking, and Flight Booking Services: Refunds for these services are subject to the cancellation and refund policies of the respective service providers.</li>
        </ul>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.2 Refund Process:</h3>
        <p className="mb-4">Customers requesting refunds for eligible services must contact Maya customer support and provide relevant details, including the order number, description of the issue, and any supporting evidence (e.g., photos). Maya customer support will review the request and initiate the refund process if deemed appropriate.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.3 Refund Methods:</h3>
        <p className="mb-4">Refunds are typically processed using the original payment method used for the booking. In some cases, alternative refund methods may be offered at the discretion of Maya Multipurpose Pvt Ltd.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Refund Timeframe</h2>
        <p className="mb-4">The timeframe for processing refunds may vary depending on the payment method and financial institution. Generally, refunds are processed within 7-14 business days from the date of approval. Customers will be notified via email or the Maya App once the refund has been processed.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Contact Information</h2>
        <p className="mb-4">For inquiries or assistance regarding returns and refunds, customers are encouraged to contact Maya customer support at support@mayanp.com. Our dedicated support team is available to assist customers with any concerns or issues they may encounter.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Policy Updates</h2>
        <p className="mb-4">Maya Multipurpose Pvt Ltd reserves the right to modify, update, or revise this Return and Refund Policy at any time without prior notice. Customers are encouraged to review this policy periodically for any changes or updates.</p>

        <p className="mb-4">Effective Date: [Insert Effective Date]</p>
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default ReturnAndRefund;