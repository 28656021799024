import Footer from "../Footer";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronRight } from "@fortawesome/free-solid-svg-icons";
function Privacypolicy() {
    return (
        <div className="bg-[#F4EFED]">
            <Navbar />
            <div className="bg-[#F4EFED] min-h-screen py-4 font-poppins">
                <div className=" mx-4 md:mx-8 px-4 text-gray-700 ">
                <nav className="mb-4 pt-8 text-xs md:text-sm">
                        <Link to="/privacy" className="text-[#D60620]">Categories</Link>
                        <span className="mx-2"><FontAwesomeIcon icon={faChevronRight}/></span>
                        <span className="text-[#D60620] ">Privacy Policy</span>
                    </nav>
                    <div className="text-center  pb-8">
                        <h1 className=" text-3xl md:text-4xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">Privacy Policy</h1>
                    </div>
                    

                    <p className="mb-4">Update On: April 30, 2024</p>

                    <section className="mb-8">
                        <p className="mb-4">Welcome to Maya, the versatile platform brought to you by Maya Multipurpose Pvt Ltd, designed to cater to all your needs. Your privacy and data security are our top priorities. Here's a glimpse into how we handle your personal information while you enjoy Maya's range of services.
                            Maya Multipurpose Pvt Ltd is the powerhouse behind Maya, orchestrating all data processing activities (referred to as "we" or "us").
                            This policy governs your interactions with Maya's websites, mobile apps, products, content, and services (collectively referred to as the "Platform"), as well as any communication with our team. It's important to note that this policy does not cover how we handle information related to our candidates, employees, or business partners.
                            Our commitment to privacy extends globally, although certain countries may have specific privacy policies in place. We pledge to comply with all relevant local laws regarding the practices outlined in this policy. Should any inconsistency arise between this policy and local regulations, we will defer to the local laws.
                            Users who utilize Maya for transportation, delivery, or booking services are known as "Customers," while those providing these services are referred to as "Partners."
                            Please read below to learn more about our information practices. By using this Site, you agree to these practices.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">What Information We Collect</h2>
                        <p className="mb-4">Maya gathers a diverse array of information to facilitate the provision of our services and enhance user experiences. This includes:</p>
                        <h3 className="text-2xl font-bold mb-2">Informatin you provide:</h3>
                        <p className="mb-4">At Maya, we understand the importance of the information you entrust to us to access our services. Here's a detailed breakdown of the data you may be required to provide:</p>
                        <h3 className="text-xl font-semibold mb-2">User Information</h3>
                        <p className="mb-4">When registering as a user, whether a passenger or a service provider, you'll need to furnish essential details including your first name, phone number, email address, country, and city. For safety and verification purposes, we may also request additional information such as your selfie image for liveness checks, national ID numbers, and social media accounts. Emergency contact numbers are also encouraged to enhance safety measures. Failure to provide this information may limit your access to certain features of the Maya platform.
                        </p>

                        <h3 className="text-xl font-semibold mb-2">Partner Information:</h3> <p className="mb-4">For individuals registering as partners across various services offered by Maya, including Ride Sharing, Hotel Booking, Food Delivery, Courier Delivery, Bus Ticketing, and Flight Booking, we require comprehensive information to ensure compliance and safety. Below is an outline of the details partners must provide:</p>

                        <h3 className="text-lg font-semibold mb-2">Personal Details:</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Full Name: Partners are required to provide their complete legal name for verification purposes.</li>
                            <li>Profile Picture: A clear and recent photograph of the partner's face is necessary to verify identity and enhance user trust.</li>
                            <li>Date of Birth: Partners must provide their date of birth for age verification and compliance purposes.</li>
                            <li>Gender:Gender information may be requested for certain regulatory requirements.</li>
                            <li>National ID Numbers: Where permitted or required by law, partners may need to submit national identification numbers for identity verification.</li>
                            <li>Proof of Identity: Partners may be asked to provide proof of identity documents such as a driver's license or passport.
                            </li>
                            <li>Proof of Residency:Documentation verifying the partner's residential address may be required for compliance purposes.</li>
                            <li>Taxation Identifier Number: In jurisdictions where applicable, partners may need to provide taxation identifier numbers for tax reporting purposes.
                            </li>
                        </ul>
                        <h3 className="text-lg font-semibold mb-2">Business Details:</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Services Offered: Partners should specify the services they intend to provide on the Maya platform, such as Ride Sharing, Hotel Booking, Food Delivery, Courier Delivery, Bus Ticketing, or Flight Booking.</li>
                            <li>License Data and Status: For certain services like Ride Sharing or Food Delivery, partners may need to provide information about their professional licenses and their current status.</li>
                            <li>Vehicle Information: For partners offering services such as Ride Sharing or Courier Delivery, details about the vehicle, including type, make, model, year of manufacture, color, registration certificate data, license plate number, and vehicle inspection report, are necessary.
                            </li>
                            <li>Relevant Insurance: Partners providing services involving vehicles may be required to submit proof of relevant insurance coverage.
                            </li>
                            <li>Right to Work: Documentation confirming the partner's legal right to work in their respective jurisdiction may be requested.</li>
                        </ul>
                        <h3 className="text-lg font-semibold mb-2">Payment and Banking Information:</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>
                                Payment Preferences: Partners may need to specify their preferred payment methods for receiving earnings from Maya services.
                            </li>
                            <li>
                                Banking Information: Bank account details may be required for electronic fund transfers or payment processing.
                            </li>
                        </ul>
                        <h3 className="text-lg font-semibold mb-2">Other Documents:</h3>
                        <ul className="list-disc pl-6 mb-4">
                            <li>
                                Background Checks: Maya may conduct background checks on partners in accordance with applicable regulations and platform policies.
                            </li>
                            <li>
                                Criminal Records Information: Where permitted or required by law, partners may need to disclose information regarding criminal records.
                            </li>
                            <li>Additional Requirements: Partners should be prepared to provide any other documents or information that may be necessary to comply with local laws and regulations or Maya's policies.</li>
                        </ul>
                        <h3 className="text-lg font-semibold mb-2">User-Generated Content:</h3>
                        <li >
                            Users have the freedom to contribute content to Maya, including comments, ratings, and reviews. This user-generated content enriches the platform and provides valuable insights for both users and service providers.
                        </li>
                        <h3 className="text-lg font-semibold mb-2">Correspondence Information:</h3>
                        <li >
                            Any communication sent to Maya, including chat messages, emails with attachments, and recordings of phone calls, along with related metadata, may be collected and stored. This ensures effective communication and allows us to address inquiries or concerns promptly.
                        </li>
                        <h3 className="text-lg font-semibold mb-2">Promotional Information:</h3>
                        <li>
                            Participation in promotional programs such as car branding or referral initiatives may require the provision of additional information.
                        </li>
                    </section>
                    <section className="mb-8">
                        <p className="text-xl font-bold mb-2">
                            Information We Collect Automatically:
                        </p>
                        <ul className="list-disc list-inside mb-4">
                            <li className="">

                                Location Data Collection: To facilitate rides, enhance user support, ensure safety measures, detect fraudulent activities, and adhere to legal obligations, Maya collects location data from users. This includes GPS coordinates and WiFi data, obtained through the Maya app settings and device permissions:
                                <li>
                                    For Users: Maya collects precise location data when the app is active, both in the foreground (visible on-screen) and background (running but not on-screen), from ride request to completion. Users may alternatively input pick-up and drop-off details directly into the app without enabling location services.
                                </li>
                                <li>
                                    For Maya Partners: Precise location data is collected while the app is active, both in foreground and background, specifically in Driver mode. Additionally, location data may be collected briefly after exiting Driver mode to address and investigate ride-related incidents.

                                </li>
                            </li>
                            <li className="">
                                Transaction Details: Maya collects transaction information pertaining to services rendered via the platform, encompassing payment details, service date and time, route information, and distance traveled.

                            </li>
                            <li className="">
                                Usage Insights: Data concerning platform usage, including access times, viewed features, system crashes, and other system activities, are gathered. Maya may also utilize data related to third-party services utilized prior to engaging with the platform for marketing purposes.

                            </li>
                            <li className="">
                                Communication Records: Facilitating user-to-user and user-to-platform communications involves collecting data such as communication timestamps and content. This information aids in user support, dispute resolution, rule enforcement, safety assurance, and platform enhancement.
                            </li>
                            <li className="">
                                Device Information: Information regarding the devices utilized to access Maya, encompassing device specifications, network details, system settings, and installed applications, is gathered for authentication and anti-fraud purposes. Additionally, mobile sensor data, such as speed and direction, may be collected for technical insights.
                            </li>
                            <li>
                                User and uses Identification: Unique user and uses identifiers are automatically assigned upon account creation and uses initiation, respectively.
                            </li>
                            <li className="">
                                Cookies and Tracking Technologies: Maya employs cookies, pixels, tags, and similar tracking technologies to gather information. For comprehensive details on these technologies, refer to our Cookie Policy.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>
                        <p className="mb-4">Utilizing personal information, we employ various strategies for the following purposes within Maya:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>Enabling Usage of Maya Services:  </li>
                            <ul className="list-disc pl-6 mb-4">
                                <li>Facilitating user account management and service requests.</li>
                                <li> Verifying Maya Partners' eligibility and capability to offer services.
                                </li>
                                <li>Mitigating risks associated with unsafe or unlawful conduct, fraud, and policy violations, with measures like user suspension or deactivation.</li>
                                <li>
                                    Providing user assistance, managing complaints, and delivering non-marketing communications such as transaction notifications.
                                </li>
                                <li>
                                    Ensuring the accessibility, security, and stability of our platform to address technical issues promptly.
                                </li>

                            </ul>
                            <p>Legal basis: Execution of contractual obligations, adherence to legal mandates, and safeguarding Maya as a secure environment.</p>
                            <li>Enhancing and Innovating Features: </li>
                            <ul className="list-disc pl-6 mb-4">
                                <li>Conducting testing, research, and data analysis to enhance user experience.</li>
                                <li>Soliciting and integrating user feedback into feature development.</li>
                            </ul>
                            <p>Legal basis: Pursuing our commitment to deliver exceptional Maya experiences. For specific uses requiring consent, we obtain it separately.</p>
                            <li>Promoting Maya:</li>
                            <ul className="list-disc pl-6 mb-4">
                                <li>Sending marketing communications, advertisements, and conducting marketing outreach.</li>
                                <li>Delivering personalized content and advertisements both on Maya and external platforms.</li>
                                <li>Organizing promotional events, contests, and referral programs to engage users.</li>
                            </ul>
                            <p>Legal basis: Advancing the visibility of Maya through legitimate promotional activities. For certain marketing initiatives requiring explicit consent, we seek it independently.</p>
                            <li>Ensuring Compliance and Protection:</li>
                            <ul className="list-disc pl-6 mb-4">
                                <li>Adhering to licensing and regulatory requirements, as well as other legal obligations.</li>
                                <li>Responding to legal requests, governmental inquiries, and protecting Maya's and users' rights.</li>
                            </ul>
                            <p>Legal basis: Fulfilling legal responsibilities and safeguarding the interests of the Maya community</p>
                        </ul>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Automated Decision Making</h2>

                        <p className="mb-4">Utilizing personal data, we employ automated processes to enhance the functionality of our platform:</p>
                        <ul className="list-disc pl-6 mb-4">
                            <li>
                                Facilitating the connection between Maya Partners and Users, considering factors like availability and proximity.
                            </li>
                            <li>Evaluating user ratings and implementing measures such as deactivation for those with consistently low ratings.
                            </li>
                            <li>Identifying and taking action against users involved in fraudulent or harmful activities, including temporary suspension or permanent deactivation where necessary.</li>
                            <li>Providing fare recommendations based on variables like distance, location, and time to ensure fairness and transparency.</li>
                        </ul>
                        <p>
                            In compliance with legal requirements, decisions resulting from these automated processes undergo human review or offer avenues for appeal. Should you wish to contest a decision, please reach out to us through our support channels support@mayanp.com..
                        </p>
                    </section>
                    <section>

                        <h2 className="text-2xl font-bold mb-4">How We Share Your Information</h2>

                        <p className="mb-4">
                            Facilitating seamless experiences for Users and Maya Partners involves sharing particular user information to enhance the overall experience:
                        </p>

                        <ul className="list-disc ml-8 mb-4">
                            <li>
                                <strong>User's Information:</strong> When utilizing our platform for rides, deliveries, ticketing, or bookings, Users may share details such as their name, contact information, pickup/delivery locations, preferences, and any special instructions.
                            </li>
                            <li>
                                <strong>Maya Partner's Information:</strong> Conversely, Maya Partners may provide information including their name, contact details, vehicle details, availability, and service offerings.
                            </li>
                            <li>
                                <strong>Transactional Details:</strong> For payment processing and transaction fulfillment, relevant details such as payment method, transaction amount, and transaction history may be shared as necessary.
                            </li>
                            <li>
                                <strong>Location Data:</strong> Location information, including pickup/delivery locations and real-time tracking during transit, may be shared to ensure accurate service provision.
                            </li>
                            <li>
                                <strong>Service-Specific Information:</strong> Depending on the type of service requested, additional details relevant to that service may be shared to facilitate smooth execution, such as flight details for booking services or package dimensions for delivery services.
                            </li>
                        </ul>

                        <p className="mb-4">
                            We may share your personal information with various entities for a range of business purposes, including:
                        </p>

                        <ul className="list-disc ml-8 mb-4">
                            <li>Facilitating payment processing and ensuring smooth transaction fulfillment.</li>
                            <li>Providing user and technical support to address any inquiries or issues.</li>
                            <li>Conducting background checks and identity verification processes.</li>
                            <li>Securing appropriate insurance coverage for our services.</li>
                            <li>Leveraging cloud storage solutions for efficient data management.</li>
                            <li>Enabling content delivery, in-app messaging, calling features, and push notifications.</li>
                            <li>Implementing anti-fraud measures to safeguard user interests.</li>
                            <li>Engaging in advertising, marketing, and analytics activities, including partnerships with platforms.</li>
                            <li>Conducting research and surveys to gather valuable insights.</li>
                            <li>Administering bonuses and other promotional benefits to enhance user engagement.</li>
                            <li>Seeking professional assistance from consultants, lawyers, accountants, auditors, and other experts.</li>
                            <li>Coordinating with airports and other relevant entities to support service operations.</li>
                        </ul>

                        <p className="mb-4">
                            Additionally, certain partners operate as independent controllers, processing data for their own purposes. Notably, entities like Google and social media companies may handle data independently in connection with our use of their respective tools.
                        </p>

                        <p className="mb-4">
                            Internally, we may share your information within our corporate group, encompassing parent, subsidiary, or affiliate entities, to facilitate seamless operations across our global network.
                        </p>

                        <p className="mb-4">
                            In the event of a substantial corporate transaction, such as a merger or acquisition, personal information may be transferred as part of the assets involved.
                        </p>

                        <p className="mb-4">
                            Legal requirements may necessitate the disclosure of your personal information to protect rights and comply with applicable laws and regulations.
                        </p>

                        <p className="mb-4">
                            With your explicit consent or direction, we may share your personal information for specific purposes beyond those outlined above. While ensuring privacy and security, sharing these specific user details allows us to optimize service delivery and provide users with a seamless and personalized experience across our platform.
                        </p>

                        <h2 className="text-2xl font-bold mb-4">Your Rights and Choices</h2>

                        <ul className="list-disc ml-8 mb-4">
                            <li>
                                Accessing, reviewing, and updating your information within our platform is effortless. Whether adjusting account settings in the app or seeking assistance via our contact page, managing your data is convenient. Additionally, if you require a comprehensive overview of your data, we're pleased to provide it in a structured, machine-readable format upon request, subject to automated processing.
                            </li>
                            <li>
                                Deleting your account and associated data is a simple process. You can initiate deletion through the app settings or request it via our support team. However, certain conditions, such as outstanding debts or unresolved issues, may necessitate a delay in deletion. Furthermore, we may retain some data post-deletion for legitimate purposes, as outlined in our data retention policy.
                            </li>
                            <li>
                                Concerning location information, your device settings offer control over data sharing. By adjusting these settings, you can manage the sharing of location information according to your preferences.
                            </li>
                            <li>
                                Should you wish to object to our data processing practices or withdraw consent, various options are available. Opting out of push notifications, unsubscribing from marketing communications, and managing marketing calls or cookies are among the provided mechanisms. Additionally, if you need to restrict our use of your data, you can request limitations, although we may still process data as required by law.

                            </li>
                            <li>
                                In the event of dissatisfaction or concerns, you have the right to file a complaint with the relevant supervisory authority in accordance with local regulations. We are dedicated to addressing any issues promptly and transparently to uphold compliance and ensure user satisfaction.
                            </li>
                        </ul>

                        <p className="mb-4">
                            For any queries or concerns, please do not hesitate to contact us at <a href="mailto:support@mayanp.com" className="text-blue-600 underline">support@mayanp.com</a>.
                        </p>

                        <h2 className="text-2xl font-bold mb-4">How We Store Your Information</h2>

                        <p className="mb-4">
                            By using our services, you agree to allow Maya to collect, use, and process your Personal Data for various purposes outlined in this Agreement. Personal Data refers to identifiable information such as your name, contact details, identification numbers, and sensitive data like health or religious beliefs. Providing this information is voluntary, but failure to do so may result in incomplete service requests or inability to use our services. Maya may utilize your Personal Data for contractual obligations, service provision, event participation, payment processing, customer communication, internal administrative tasks, crime detection/prevention, legal compliance, and marketing communications. Additionally, your data may be shared with affiliated companies, agents, third-party providers, and sponsors for relevant purposes. If you disagree with any data processing purposes, please notify Maya. Furthermore, any changes to your provided information should be promptly communicated to Maya for updates within a reasonable timeframe.

                        </p>

                        <p className="mb-4">
                            Regarding data retention, Maya retains your personal information as long as your account is active and may keep certain data even after account termination for lawful reasons. This includes transactional details for tax compliance, regulatory requirements, commercial correspondence, legal claims or disputes, fraud prevention, and safety measures. The retention period varies depending on the nature of the data and applicable regulations. Rest assured, Maya is committed to protecting your information and ensuring compliance with legal obligations while maintaining service integrity and user safety.

                        </p>

                        <h2 className="text-2xl font-bold mb-4">How We Protect Your Information</h2>

                        <p className="mb-4">
                            At Maya, safeguarding your information is paramount. We advise users to protect their login credentials diligently, preventing unauthorized access to their accounts. We remain vigilant against phishing scams and identity theft, ensuring that sensitive data such as credit card details or passwords is never solicited through insecure channels. In the event of a security breach, we pledge to notify affected users promptly via email or a conspicuous platform notice, collaborating with law enforcement to restore data integrity. For any security concerns or inquiries, our dedicated support team is available at  <a href="mailto:support@mayanp.com" className="text-blue-600 underline">support@mayanp.com</a>, committed to maintaining a safe user environment.
                        </p>

                        <h2 className="text-2xl font-bold mb-4">Changes</h2>

                        <p className="mb-4">
                            Maya reserves the right to update this Privacy Policy to reflect evolving business or legal requirements. Users will be duly notified of any amendments.
                        </p>
                        <h2 className="text-2xl font-bold mb-4">Data Subject to Nepal Law</h2>

                        <p className="mb-4">
                            Maya, based in Nepal, operates its platform within the country's jurisdiction. When using our website or app, please note that the information you provide and that we collect may be stored on servers located outside of your local jurisdiction. These servers may reside in countries with varying privacy laws compared to those in your own jurisdiction, potentially offering different levels of protection for your personal data.
                            If you reside outside of Nepal and choose to provide us with your information, please be aware that your personal data may be transferred to other countries and processed there. By using our website or app, you consent to the transfer and processing of your data as described.
                            At Maya, we are committed to adhering to applicable laws and regulations regarding data protection, including those of Nepal. If you have any questions or concerns about how your data is handled, please don't hesitate to contact us at <a href="mailto:support@mayanp.com" className="text-blue-600 underline">support@mayanp.com</a>
                        </p>
                        <h2 className="text-2xl font-bold mb-4">Children</h2>

                        <p className="mb-4">
                            While Maya's platform is accessible to all visitors, registration and use of our services are restricted to adults only. Individuals must be of legal adult age to register on our website or app and utilize our services. We do not knowingly collect information about children under the age of 18.
                            If you are a parent or guardian who believes that we have inadvertently collected information about a child under the age of 18, please reach out to us at <a href="mailto:support@mayanp.com" className="text-blue-600 underline">support@mayanp.com</a>. Provide your child’s name and address, and we will promptly delete any information we have about your child from our records.
                            At Maya, we are committed to ensuring the safety and privacy of all users, especially minors. Your cooperation in this matter is greatly appreciated.

                        </p>
                        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>

                        <p className="mb-4">
                            For queries or concerns regarding our Privacy Policy or data practices, please contact us at <a href="mailto:support@mayanp.com" className="text-blue-600 underline">support@mayanp.com</a>
                        </p>
                    </section>

                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Privacypolicy;