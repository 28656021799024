import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
function TermsOfServiceRideandDeliveryPartner() {
  return (
    <div className='bg-[#F4EFED]'>
      <Navbar />
      <div className="bg-[#F4EFED] font-poppins text-gray-700 py-8 px-8 md:p-16  ">
        <nav className="mb-4  text-xs md:text-sm">
          <Link to="/terms-and-condition" className="text-[#D60620]">Categories</Link>
          <span className="mx-2"><FontAwesomeIcon icon={faChevronRight} /></span>
          <span className="text-[#D60620] ">Terms of service agreement for ride and delivery partner</span>
        </nav>
        <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-black font-PPAgrandir">Terms of Service Agreement</h1>
        <div className='text-justify'>
          <p className="mb-4">Maya Multipurpose Pvt Ltd</p>
          <p className="mb-4">Application: Maya</p>

          <p className="mb-8">
            This comprehensive Terms of Service Agreement ("Agreement") constitutes a legally binding contract between Maya Multipurpose Pvt Ltd ("Maya"), a distinguished company registered in Nepal, and you, a valued Maya Partner ("Partner"). This Agreement meticulously outlines the terms and conditions governing your esteemed use of the cutting-edge Maya application ("App") and the diverse array of invaluable services provided through its innovative platform, including but by no means limited to Taxi Booking (Immediate, Scheduled, and Sharing), Bike Ride, Food Delivery, and Courier Delivery.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Acknowledgment and Acceptance of Terms</h2>
          <p className="mb-4">
            By conscientiously accessing or utilizing the Maya App, you unmistakably acknowledge and unequivocally accept the meticulously delineated terms and conditions of this Agreement. In the event of any objection or dissent to any aspect of this Agreement, it is incumbent upon you to refrain from accessing or utilizing the App forthwith.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Description and Scope of Services</h2>
          <p className="mb-4">
            The Maya platform, an exemplary epitome of technological ingenuity and operational excellence, seamlessly facilitates the multifaceted provision of transportation and delivery services to Maya's esteemed clientele. Through the App, Maya Partners, including esteemed Taxi Drivers, Bike Riders, and Delivery Personnel, conscientiously undertake the provision of a rich spectrum of services. These services encompass but are by no means limited to:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Taxi Booking: Through the Maya platform, Partners are endowed with the exceptional ability to extend immediate, scheduled, or shared rides, thereby ushering in unparalleled convenience and flexibility for passengers.</li>
            <li>Bike Ride: Partners adeptly ferry passengers to their respective destinations with utmost efficiency and expediency, utilizing bikes as a swift and eco-friendly mode of transportation.</li>
            <li>Food Delivery: The Maya platform seamlessly facilitates the seamless conveyance of delectable culinary delights from esteemed restaurants to the discerning palates of customers, ensuring optimal satisfaction and gastronomic delight.</li>
            <li>Courier Delivery: Partners diligently undertake the responsibility of transporting packages and parcels to designated destinations with the utmost promptitude and reliability, thereby fostering enhanced convenience and efficiency in the delivery of goods.</li>
          </ul>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Partner Obligations and Commitments</h2>
          <p className="mb-4">
            As an esteemed Maya Partner, you are unequivocally committed to the unwavering fulfillment of your esteemed obligations in strict accordance with the highest standards of professionalism, integrity, and ethical conduct. Your esteemed responsibilities encompass but are by no means limited to:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>The scrupulous provision of transportation and delivery services with the utmost diligence, courtesy, and professionalism, thereby ensuring optimal satisfaction and safety for passengers and recipients of delivered goods.</li>
            <li>The conscientious adherence to all pertinent laws, regulations, and licensing prerequisites governing your esteemed operations, thereby exemplifying unwavering compliance and regulatory adherence.</li>
            <li>The assiduous maintenance of the cleanliness, safety, and operational efficiency of your esteemed vehicle, in the case of Taxi and Bike services, thereby ensuring optimal comfort and safety for passengers.</li>
            <li>The safe and secure conveyance of passengers, food items, or parcels to their designated destinations, thereby instilling confidence and trust among customers and recipients.</li>
            <li>The judicious utilization of the Maya Partner App solely for the purposes delineated in this Agreement, thereby safeguarding the integrity and security of the esteemed Maya platform and its associated services.</li>
          </ul>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Payment Terms and Compensation Structure</h2>
          <p className="mb-4">
            In recognition of the invaluable contributions and dedicated efforts of esteemed Maya Partners, Maya Multipurpose Pvt Ltd is duly committed to compensating Partners for their esteemed services in strict accordance with the rates and payment terms meticulously specified by Maya. The intricate details pertaining to the compensation structure, frequency of payments, and disbursement modalities shall be comprehensively elucidated in the Maya Partner Agreement.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">5. License Grant and Restriction of Usage</h2>
          <p className="mb-4">
            In a noble endeavor to empower and equip esteemed Maya Partners with the requisite tools and resources to deliver unparalleled transportation and delivery services, Maya extends a limited, non-exclusive, and non-transferable license to Partners for the esteemed utilization of the Maya App. This esteemed license is exclusively designated for the purpose of providing transportation and delivery services and shall not be sublicensed, sold, or otherwise transferred to any third party without the explicit consent of Maya Multipurpose Pvt Ltd.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Payment Facilitation and Processing</h2>
          <p className="mb-4">
            Customers availing themselves of the exceptional transportation and delivery services facilitated by the esteemed Maya platform are graciously afforded the esteemed opportunity to remunerate for their services through the App. In a commendable demonstration of operational efficiency and financial acumen, Maya dutifully undertakes the facilitation of payment processing and expeditiously transfers the requisite portion of the payment to esteemed Partners, after deducting any applicable fees or commissions.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">7. Tax Compliance and Obligations</h2>
          <p className="mb-4">
            In recognition of the esteemed fiscal responsibilities and obligations borne by esteemed Maya Partners, it is incumbent upon Partners to diligently report and remit any taxes applicable to the income derived from their esteemed provision of services on the esteemed Maya platform, thereby exemplifying unwavering compliance with regulatory requirements and fiscal obligations.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">8. Personal Data Protection and Confidentiality</h2>
          <p className="mb-4">
            Maya Multipurpose Pvt Ltd holds the esteemed protection and confidentiality of personal data in the highest regard. In a commendable demonstration of unwavering commitment to data protection and privacy, esteemed Partners are duly obligated to rigorously adhere to Maya's Privacy Policy and diligently safeguard any personal information obtained or processed through the esteemed Maya App, thereby ensuring the sanctity and confidentiality of sensitive personal data.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">9. Third-Party Interactions and Collaborations</h2>
          <p className="mb-4">
            Esteemed Partners may engage in collaborative endeavors and interactions with third-party service providers or customers while availing themselves of the esteemed Maya platform. It is imperative to underscore that Maya assumes no liability or responsibility for any interactions, disputes, or transactions that may ensue between esteemed Partners and third parties, thereby affirming the autonomous nature of such interactions.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">10. Disclaimer of Warranties</h2>
          <p className="mb-4">
            In a commendable demonstration of transparency and candor, Maya unequivocally disclaims any and all warranties, whether express or implied, pertaining to the esteemed Maya App and associated services. While Maya tirelessly endeavors to provide services of impeccable quality and reliability, Maya does not warrant or guarantee the accuracy, reliability, or suitability of the services for any particular purpose, thereby fostering informed decision-making and conscientious utilization of the esteemed Maya platform.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">11. Internet Delays and Operational Disruptions</h2>
          <p className="mb-4">
            Maya earnestly endeavors to ensure the seamless and uninterrupted provision of esteemed services through the Maya platform. However, it is imperative to acknowledge and comprehend that Maya shall not be held liable or accountable for any delays, interruptions, or operational disruptions arising from the transmission of data over the internet or through the esteemed Maya App. Esteemed Partners are kindly urged to exercise patience and understanding in such exigent circumstances, thereby fostering a culture of resilience and adaptability.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">12. Limitation of Liability</h2>
          <p className="mb-4">
            In a commendable demonstration of prudence and circumspection, it is imperative to underscore that Maya shall not, under any circumstances, be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the utilization of the esteemed Maya App or the services facilitated thereby. Even in the unlikely eventuality of Maya being apprised of the potentiality of such damages, Maya shall not be held liable or accountable for any such damages, thereby reaffirming the esteemed ethos of operational prudence and fiscal responsibility.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">13. Notice and Communication Protocols</h2>
          <p className="mb-4">
            Any and all notices or communications mandated or permitted under this illustrious Agreement shall be judiciously dispatched to the esteemed email address provided by the respective party. Esteemed Partners are courteously urged to exercise due diligence and circumspection in promptly responding to any such notices or communications, thereby fostering effective communication and collaboration.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">14. Intellectual Property Rights and Proprietary Assets</h2>
          <p className="mb-4">
            Esteemed Partners are kindly urged to unequivocally acknowledge and respect the esteemed intellectual property rights and proprietary assets associated with the esteemed Maya App and associated services. All intellectual property rights, inclusive of but not limited to trademarks, patents, copyrights, and trade secrets, shall be judiciously safeguarded and preserved in accordance with prevailing laws and regulatory frameworks.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">15. Amendments and Revisions</h2>
          <p className="mb-4">
            In recognition of the dynamic and evolving nature of esteemed business operations, Maya reserves the esteemed prerogative to effectuate amendments or revisions to this illustrious Agreement at its sole discretion and prerogative. Esteemed Partners shall be diligently apprised of any such amendments or revisions, and continued utilization of the esteemed Maya App subsequent to such amendments or revisions shall be construed as unequivocal acceptance thereof.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">16. Governing Law and Jurisdiction</h2>
          <p className="mb-4">
            Esteemed Partners are kindly urged to duly acknowledge and comprehend that this illustrious Agreement shall be governed by and construed in strict accordance with the laws of Nepal. In the unlikely eventuality of any disputes or controversies arising under this esteemed Agreement, the esteemed jurisdiction of the courts of Nepal shall be unequivocally recognized and respected.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">17. Indemnification</h2>
          <p className="mb-4">
            Esteemed Partners hereby undertake to indemnify and hold Maya Multipurpose Pvt Ltd harmless from any claims, damages, losses, or liabilities arising from their esteemed utilization of the Maya platform or any breach of the stipulations delineated in this illustrious Agreement, thereby fostering a culture of accountability and corporate responsibility.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">18. Dispute Resolution Mechanisms</h2>
          <p className="mb-4">
            Esteemed Partners are kindly encouraged to amicably resolve any disputes or controversies arising under this illustrious Agreement through diligent negotiation, mediation, or arbitration, as per the esteemed dispute resolution mechanisms meticulously outlined in the esteemed Maya Partner Agreement, thereby fostering a culture of mutual respect and conflict resolution.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">19. Termination of Services</h2>
          <p className="mb-4">
            Maya reserves the esteemed prerogative to suspend or terminate the esteemed access of any esteemed Partner to the esteemed Maya App and associated services in the unlikely event of any violation of the stipulations delineated in this illustrious Agreement, failure to meet esteemed performance standards, or engagement in any behavior deemed detrimental to the esteemed reputation or operational integrity of Maya or its esteemed clientele.
          </p>

          <h2 className="text-xl md:text-2xl font-semibold mb-4">20. Effective Date and Affirmative Acknowledgment</h2>
          <p className="mb-4">
            Esteemed Partners hereby acknowledge, affirm, and unequivocally declare that they have meticulously perused, comprehended, and assented to be bound by all the illustrious provisions delineated in this esteemed Agreement. The esteemed commencement of the utilization of the Maya App subsequent to the esteemed Effective Date shall be construed as unequivocal acceptance of the terms and conditions stipulated herein.
          </p>

          <p className="mb-4">
            <strong>Contact Information:</strong><br />
            For any inquiries, clarifications, or elucidations pertaining to this illustrious Agreement, esteemed Partners are courteously invited to contact Maya Multipurpose Pvt Ltd at <a href="mailto:support@mayanp.com" className="text-blue-500 underline">support@mayanp.com</a>.
          </p>

          <p>
            <strong>Effective Date:</strong> [Insert Effective Date]
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfServiceRideandDeliveryPartner;
