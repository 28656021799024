import { useState } from 'react';
import CustomerCare from '../image/customer-care.jpg'
import FAQ from './FAQ';
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Helmet } from 'react-helmet-async';
function HelpPage() {
  const Customerfaqs = [
    {
      question: "How do I book a ride using Maya?",
      answer: "To book a ride with Maya, simply open the app, choose the \"Ride Booking\" service, enter your pickup and drop-off locations, select between taxi or bike, and confirm your booking. You can choose options like instant booking, scheduled rides, or ride-sharing based on your preference."
    },
    {
      question: "Can I schedule a ride in advance with Maya?",
      answer: "Yes, Maya allows you to schedule rides in advance. When booking a ride, select the \"Schedule\" option, choose your preferred date and time, and confirm your booking. Maya will ensure your ride is ready at the specified time."
    },
    {
      question: "How does ride-sharing work on Maya?",
      answer: "Maya offers ride-sharing options to help you split costs with others traveling along similar routes. Simply select the \"Sharing\" option when booking a ride, and Maya will match you with compatible passengers heading in the same direction."
    },
    {
      question: "How do I order food through Maya?",
      answer: "Ordering food with Maya is easy. Navigate to the \"Food Order\" service, browse restaurants near you, select items from the menu, add them to your cart, proceed to checkout, and confirm your order. You can track your food delivery in real-time."
    },
    {
      question: "Can I track my food delivery status with Maya?",
      answer: "Yes, Maya provides real-time tracking for food deliveries. Once your order is confirmed, you can monitor the progress of your delivery and receive updates on the estimated time of arrival (ETA) directly within the app."
    },
    {
      question: "How do I book a hotel room using Maya?",
      answer: "To book a hotel room, go to the \"Hotel Booking\" section in Maya, enter your destination and travel dates, browse available hotels, select a room type, and complete your reservation by making a secure payment through the app."
    },
    {
      question: "Can I purchase bus tickets through Maya?",
      answer: "Yes, Maya offers bus ticketing services. Navigate to the \"Bus Ticketing\" section, enter your departure and arrival locations, select your travel dates, choose from available bus options, and confirm your seats to complete your booking."
    },
    {
      question: "Is customer support available if I encounter issues with my bookings?",
      answer: "Maya provides comprehensive customer support. You can access help articles, FAQs, and contact customer service directly through the app for assistance with any booking-related queries or issues."
    }
  ];

  const Partnerfaqs = [
    {
      question: "How can I partner with Maya as a taxi or bike driver?",
      answer: "To partner with Maya, sign up through the Maya Partner Portal, provide necessary documentation such as vehicle registration and insurance, set your availability, and start receiving ride bookings from customers through the app."
    },
    {
      question: "What are the benefits of partnering with Maya as a taxi or bike driver?",
      answer: "Partnering with Maya expands your customer base and visibility. You gain access to a large pool of users actively seeking transportation services, with features for managing bookings, tracking earnings, and accessing support."
    },
    {
      question: "How do I list my hotel or accommodation services on Maya?",
      answer: "As a hotel owner or manager, list your property on Maya by creating a partner account, uploading property details and photos, setting room availability, and managing bookings through the Partner Dashboard provided by Maya."
    },
    {
      question: "How can restaurants join Maya for food delivery services?",
      answer: "Restaurants can join Maya by registering through the Partner Portal, submitting menu details, setting delivery zones, and partnering with Maya for food delivery services. Customers can then order directly from the app."
    },
    {
      question: "How does Maya handle payments for partners (drivers, hotels, restaurants, Bus)?",
      answer: "Maya ensures secure and timely payment processing for partners. Earnings are calculated based on completed services or bookings and can be tracked through the Partner Dashboard, with options for regular payout cycles."
    },
    {
      question: "What support is available for partners if there are issues with bookings or payments?",
      answer: "Maya offers dedicated support for partners through the Partner Portal and customer service channels. Partners can receive assistance with technical issues, booking management, payment inquiries, and more to facilitate smooth operations."
    },
    {
      question: "Can partners manage their availability and services through Maya?",
      answer: "Yes, partners can manage availability and service details through the Maya Partner Dashboard. This includes updating service offerings, adjusting availability times, and responding to booking requests from customers in real-time."
    },
    {
      question: "Is there training or resources available for partners new to Maya?",
      answer: "Maya provides resources and training materials to onboard new partners effectively. This includes guides on using the Partner Dashboard, optimizing service listings, and maximizing visibility and bookings through the app platform."
    }
  ];
  const [selectedFAQ, setSelectedFAQ] = useState('customer');
  return (
    <div className='bg-[#F4EFED] w-full min-h-screen font-poppins'>
      <Helmet>
        <title>Help & FAQ - Maya-The Super App</title>
        <meta name='description' content='' />
        <link rel="canonical" href="https://mayanp.com/help" />
      </Helmet>
      <Navbar />
      <div className="w-full flex flex-col items-center py-8">
        <div className='sm:w-72 md:w-80 '>
          <h1 className='text-3xl md:text-4xl font-semibold mb-6 text-center font-PPAgrandir'>Help Center!</h1>
          <div className='flex border-2 border-gray-300 rounded-2xl items-center bg-white shadow-md p-2 whitespace-nowrap'>
            <img src={CustomerCare} alt="customercare" className='h-8 sm:h-10 w-8 sm:w-10 md:h-12 md:w-12 rounded-full mr-2' />
            <h1 className='text-sm sm:text-base md:text-lg font-semibold'>Customer Care: 061-590321</h1>
          </div>
        </div>
        <div className='w-full max-w-5xl px-4 mb-8'>
          <div className='pt-8 ml-4'>
            <h2 className='text-2xl md:text-4xl font-semibold mb-6 text-start font-PPAgrandir'>Frequently asked questions ?</h2>
          </div>
          <div className='flex justify-start mb-6 space-x-2 ml-4 md:ml-6'>
            <button
              onClick={() => setSelectedFAQ('customer')}
              className={`px-2 py-1 sm:px-2 sm:py-2 rounded-2xl font-semibold text-base md:text-lg  focus:outline-none text-white ${selectedFAQ === 'customer' ? 'bg-rose-600 border-2 border-gray-300' : 'bg-black'}`}
            >
              Customer FAQs
            </button>
            <button
              onClick={() => setSelectedFAQ('partner')}
              className={`px-2 py-1 sm:px-2 sm:py-2 rounded-2xl font-semibold text-base md:text-lg  focus:outline-none  text-white ${selectedFAQ === 'partner' ? 'bg-rose-600 border-2 border-gray-300' : 'bg-black'}`}
            >
              Partner FAQs
            </button>
          </div>
          {selectedFAQ === 'customer' ? (
            <FAQ faqs={Customerfaqs} />
          ) : (
            <FAQ faqs={Partnerfaqs} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HelpPage;