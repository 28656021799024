
import './App.css';
import Home from './components/Home';
import Partners from './components/Partners';
import Services from './components/Services';
import Blog from './components/Blog';
import Download from './components/Download';
import Footer from './components/Footer';
import UserVoice from './components/UserVoice';
// import About from './components/Aboutus';

function App() {
  return (
    <div className=' font-poppins overflow-hidden w-full' >
      {/* <Navbar /> */}
      <Home/>
      <Services/>
      <Partners/>
      <Download/>
      <UserVoice/>
      <Blog/>
      {/* <About/> */}
      <Footer/>
    </div>
  );
}

export default App;
