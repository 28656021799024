import Navbar from './Navbar';
import Footer from './Footer';
import Blog from './Blog';
import aboutImage from '../image/about-img.webp';
import allImage from '../image/allinone-removebg.webp'
import ScrollReveal from './ScrollReveal';
import { Helmet } from 'react-helmet-async';

function About() {
  return (
    <div id='about' className='bg-[#F4EFED] w-full font-poppins'>
    <Helmet>
    <title>About Maya - The Super App</title>
    <meta name='description' content='Welcome to Maya, where we redefine convenience and connectivity in your daily life.'/>
    <link rel="canonical" href="https://mayanp.com/about" />
    </Helmet>
      <Navbar />
      <div className='flex flex-col items-center lg:min-h-screen lg:mx-4 xl:mx-8'>
        <div className='relative  w-[85%] h-64 md:h-128 my-4 rounded-3xl'>
          <video
            src="https://www.shutterstock.com/shutterstock/videos/1104817851/preview/stock-footage-business-woman-wave-phone-and-taxi-in-a-city-with-urban-travel-and-transport-of-chauffeur-female.webm"
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover rounded-3xl"
          ></video>
          <div className='absolute inset-0 bg-black bg-opacity-30 flex flex-col items-center justify-center rounded-3xl'>
            <h2 className='text-2xl md:text-5xl font-bold text-white'>Welcome to Maya</h2>
            <p className='mt-2 text-base text-center md:text-2xl text-white'>--Redefining Convenience and Connectivity--</p>
          </div>
        </div>

        <main className='w-full px-4 mt-4  lg:pt-8'>
          <section className='mb-8 flex flex-col md:flex-row justify-between  p-4 rounded-lg border'>
            <div className='flex flex-col justify-center w-full md:w-1/2 mb-4 md:mb-0'>
              <h2 className='text-xl md:text-2xl font-semibold text-start mb-4 md:ml-4 font-PPAgrandir'>Our Mission</h2>
              <p className='text-sm md:text-md lg:text-lg text-gray-700  text-left'>
              Our mission is to transform everyday experiences by offering reliable and convenient access to essential services such as ride-sharing for taxis and motorcycles, efficient food delivery, hassle-free hotel bookings, swift courier services, and seamless bus ticketing. We strive to enhance accessibility and convenience in urban and rural areas alike, ensuring that every interaction with Maya enriches lives and fosters lasting connections.
              </p>
            </div>
            <div className='w-full md:w-1/2 flex justify-center'>
              <ScrollReveal>
              <div className="flex justify-center">
                <img src={aboutImage} alt="Our Mission" className='w-3/4 rounded-3xl md:my-4  loading="lazy"' />
                </div>
              </ScrollReveal>
            </div>
          </section>

          <section className='flex flex-col md:flex-row justify-between items-center mb-8 p-4 border'>
            <div className=' w-full md:w-1/2 flex justify-center order-2 md:order-1'>
              <ScrollReveal >
              <div className="flex justify-center">
                <img src={allImage} alt='Service' className='w-full  object-cover rounded-3xl ' loading="lazy" />
                </div>
              </ScrollReveal>
            </div>
            <div className='w-full md:w-1/2 m-4 md:mt-0 order-1 md:order-2'>
              <h2 className='text-xl md:text-2xl text-start font-semibold mb-4 md:ml-4 font-PPAgrandir'>Comprehensive Services</h2>
              <ul className='list-disc ml-8 list-inside space-y-2 text-sm md:text-base lg:text-lg text-gray-700'>
                <li>Ride sharing</li>
                <ul className='list-disc ml-8 text-xs md:text-sm lg:text-base'>                  
                  <li>Taxis</li>
                <li>Motorcycles</li>
                </ul>
                <li>Scheduled trips for planned outings</li>
                <li>Eco-friendly ride-sharing options</li>
                <li>Bike ride service for urban explorers</li>
                <li>Food ordering service</li>
              </ul>
              <p className='mt-4 text-sm md:text-md lg:text-lg text-gray-700'>
              Each of these services is designed to provide convenience and reliability, ensuring that Maya meets the diverse needs of its users across different aspects of daily life.
              </p>
            </div>
          </section>

          {/* <section className='flex flex-col md:flex-row justify-between items-center mb-8 border'>
            <div className='w-full my-4 m-4 md:w-1/2 md:pr-4 order-2 md:order-1 mt-4 md:mt-0'>
              <h2 className='text-xl  md:text-3xl font-semibold text-left mb-4 ml-4 font-PPAgrandir'>Travel and Accommodation</h2>
              <p className='text-sm md:text-md lg:text-lg text-gray-700 text-left ml-4 md:ml-0'>
                Maya simplifies your travel planning with hassle-free hotel bookings and soon-to-be-launched flight ticketing services, ensuring you find the perfect accommodations and flights tailored to your preferences. Need to send a package urgently? Our reliable courier delivery service ensures prompt and secure transportation of your goods.
              </p>
            </div>
            <div className='w-full md:w-1/2 flex justify-center items-center order-2'>
              <ScrollReveal>
              <div className="flex justify-center">
                <img src={hotelImage} alt='Travel' className='w-3/4 md:my-4 my-2 object-cover rounded-3xl shadow-md' />
                </div>
              </ScrollReveal>
            </div>
          </section>

          <section className='flex flex-col md:flex-row justify-between items-center mb-8 border rounded-xl'>
            <div className='w-full md:w-1/2 flex justify-center mb-4 md:mb-0 order-2 md:order-1'>
              <ScrollReveal >
                <div className="flex justify-center">
                  <img src={courierImage} alt="Courier" className="w-3/4  md:my-4 h-auto object-cover rounded-3xl " />
                </div>
              </ScrollReveal>
            </div>
            <div className='w-full md:w-1/2 order-1 md:order-2 my-4'>
              <h2 className='text-xl md:text-3xl font-semibold text-left mb-4 ml-4 font-PPAgrandir'>Bus Ticketing and Courier Services</h2>
              <p className='text-sm md:text-md lg:text-lg text-gray-700 text-left ml-4 md:ml-0'>
                We're also your go-to for convenient bus ticketing, making long-distance travel a breeze. At Maya, we're not just about getting you from point A to point B – we're about enhancing every aspect of your journey with innovation, reliability, and personalized service.
              </p>
            </div>
          </section> */}
  <section className='mb-8 md:text-center border rounded-xl py-2 px-2 md:px-4'>
            <h2 className='text-xl text-start md:text-2xl font-semibold mb-4 font-PPAgrandir mx-2 md:mx-4 '>Details about services</h2>
            <div className='text-gray-700 text-sm md:text-base lg:text-lg max-w-6xl  text-left'>
              <p className='mb-2 md:mb-4'><strong className='text-gray-900'>Ride Sharing (Taxis & Motorcycles):</strong><br/> Maya provides convenient ride-sharing options with both taxis and motorcycles. Whether you're traveling alone or with a group, Maya connects you with reliable drivers to ensure swift and safe journeys across town.</p>
              <p className='mb-2'><strong className='text-gray-900'>Food Delivery:</strong><br/> Enjoy a diverse selection of cuisines delivered straight to your doorstep with Maya's food delivery service. From local favorites to international delights, Maya partners with restaurants to bring you delicious meals whenever you crave them.</p>
              <p className='mb-2'><strong className='text-gray-900'>Hotel Booking:</strong><br/> Planning a trip? Maya simplifies hotel bookings, offering a range of accommodations to suit every budget and preference. Whether for business or leisure, Maya helps you find the perfect stay with ease.</p>
              <p className='mb-2'><strong className='text-gray-900'>Courier Delivery:</strong><br/> Need to send or receive packages? Maya's courier delivery service ensures efficient and secure transportation of parcels, documents, and goods, allowing businesses and individuals to manage their logistics seamlessly.</p>
              <p className='mb-2'><strong className='text-gray-900'>Bus Ticketing:</strong><br/> Traveling by bus? Maya facilitates hassle-free bus ticket bookings, allowing you to plan your journeys in advance and secure seats for popular routes and destinations.</p>
              <p><strong className='text-gray-900'>Comprehensive Convenience:</strong><br/> Maya's integrated platform aims to enhance urban mobility and everyday convenience. By offering a wide range of essential services under one roof, Maya ensures that users can efficiently manage their transportation, dining, accommodation, logistics, and travel needs with confidence and ease.</p>
            </div>
          </section>
          <section className='mb-8 md:text-center border py-2 rounded-xl px-2 md:px-4'>
            <h2 className='text-xl text-start md:text-2xl font-semibold mb-4 font-PPAgrandir mx-2 md:mx-4'>Join Us</h2>
            <p className='text-gray-700 text-sm md:text-base lg:text-lg max-w-6xl mx-auto text-left'>
            Join the Maya community and discover a seamless world of convenience and reliability. With our diverse range of essential services including ride-sharing, food delivery, hotel booking, courier services, and bus ticketing, Maya is committed to simplifying your daily routines and enhancing your lifestyle. Experience the ease of managing all your needs in one place – join Maya and let us redefine convenience together.
            </p>
          </section>
        </main>
      </div>
      <Blog />
      <Footer />
    </div>
  );
}

export default About;
