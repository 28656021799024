import Footer from "./Footer";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet-async";
import PolicyImg from "../image/policyimg.png"
function Policy() {
    return (
        <div className=" bg-[#F4EFED] font-poppins">
            <Helmet>
                <title>Privacy Policy - Maya-The Super App</title>
                <meta name='description' content="Learn about Maya's privacy practices and how we protect your personal information while providing comprehensive services including taxi rides, food and courier delivery, bus and flight booking, and hotel reservations." />
                <link rel="canonical" href="https://mayanp.com/privacy" />
            </Helmet>
            <Navbar />
            <div className="text-center pt-8 pb-4">
                <h1 className="  text-3xl md:text-4xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">Privacy</h1>
            </div>

            <div className="container mx-auto px-4 mb-4 text-black"  >
                <h1 className="text-2xl md:text-3xl font-semibold mb-4 ml-2 font-PPAgrandir">Categories</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2 md:mx-4">
                    <a href="/policy/privacypolicy" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl">

                        <h2 className="text-lg md:text-xl font-semibold">Privacy Policy</h2>
                        <div className=" flex  text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Your privacy is important to us...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 mr-2 ">
                                <img src={PolicyImg} alt="Ride and Delivery Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/policy/referandearn" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl">
                        <h2 className="text-lg md:text-xl font-semibold">Refer and Earn Policy</h2>
                        <div className=" flex  text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Details about our referral program...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 mr-2 ">
                                <img src={PolicyImg} alt="Ride and Delivery Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/policy/returnandrefund" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl">
                        <h2 className="text-lg md:text-xl font-semibold">Return and Refund Policy</h2>
                        <div className=" flex  text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">Information regarding returns and refunds..</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 mr-2 ">
                                <img src={PolicyImg} alt="Ride and Delivery Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                    <a href="/policy/rewardpolicy" className="bg-[rgba(6,26,55,.04)] p-4 shadow-md rounded-2xl">
                        <h2 className="text-lg md:text-xl font-semibold">Rewards, Coupon and Discount Policy</h2>
                        <div className=" flex  text-justify">
                            <div className="w-2/3">
                                <p className="text-gray-800 text-sm py-2">How our rewards and discount system works...</p>
                            </div>
                            <div className="w-1/3  rounded-2xl ml-2 mr-2 ">
                                <img src={PolicyImg} alt="Ride and Delivery Partner" className="w-full h-full object-cover rounded-2xl" />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Policy;
