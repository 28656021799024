import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
function TermsOfServiceRestaurantPartner() {
  return (
    <div className='bg-[#F4EFED]'>
        <Navbar/>
   
        <div className="bg-[#F4EFED] font-poppins text-gray-700 py-8 px-8 md:p-16  ">
        <nav className="mb-4  text-xs md:text-sm">
          <Link to="/terms-and-condition" className="text-[#D60620]">Categories</Link>
          <span className="mx-2"><FontAwesomeIcon icon={faChevronRight} /></span>
          <span className="text-[#D60620] ">Terms of service agreement for restaurant partner </span>
        </nav>
      <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-black font-PPAgrandir">Terms of Service Agreement</h1>
      <div className='text-justify'>
      <p className="mb-4">Maya Multipurpose Pvt Ltd</p>
      <p className="mb-4">Application: Maya</p>
      <p className="mb-4">
        This Terms of Service Agreement ("Agreement") establishes a legally binding contract between Maya Multipurpose Pvt Ltd ("Maya"), a reputable company registered in Nepal, and you, a valued restaurant partner ("Partner"). This Agreement governs your engagement with the Maya application ("App") and outlines the terms and conditions under which you provide your esteemed culinary services to customers through the Maya platform.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing or utilizing the Maya App as a restaurant partner, you affirmatively acknowledge and agree to abide by all the terms and conditions outlined in this Agreement. Failure to adhere to any provision of this Agreement may result in termination of your partnership with Maya.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Description of Services</h2>
      <p className="mb-4">
        As a restaurant partner with Maya, you have the esteemed opportunity to showcase your culinary expertise and offer your delectable cuisine to a diverse array of customers through the Maya platform. Your services encompass but are not limited to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Preparation and delivery of food orders placed by customers through the Maya App.</li>
        <li>Providing high-quality, hygienic, and timely meals to ensure customer satisfaction.</li>
        <li>Collaborating with Maya to expand your customer base and reach a wider audience of food enthusiasts.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Registration and Onboarding</h2>
      <p className="mb-4">
        To become a restaurant partner with Maya, you must complete the registration and onboarding process as outlined by Maya Multipurpose Pvt Ltd. This process may include providing necessary documentation, agreeing to partnership terms, and undergoing training on the use of the Maya platform for managing orders and deliveries.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Menu Management</h2>
      <p className="mb-4">
        As a restaurant partner, you are responsible for managing your menu offerings on the Maya platform. This includes regularly updating your menu items, descriptions, prices, and availability to ensure accurate representation to customers.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Order Fulfillment</h2>
      <p className="mb-4">
        Upon receiving orders through the Maya platform, you are responsible for promptly preparing and packaging the requested items for delivery. Orders must be fulfilled within the specified timeframe to meet customer expectations and ensure timely delivery.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Quality Assurance</h2>
      <p className="mb-4">
        Maintaining high standards of food quality, hygiene, and presentation is paramount. As a restaurant partner, you agree to adhere to all applicable food safety regulations and standards to ensure the health and satisfaction of customers.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">7. Pricing and Payment</h2>
      <p className="mb-4">
        You have the discretion to set prices for your menu items on the Maya platform. Maya Multipurpose Pvt Ltd will facilitate payment processing and transfer the designated portion of the order revenue to you, minus any applicable fees or commissions as per the agreed-upon terms.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">8. Customer Communication</h2>
      <p className="mb-4">
        Effective communication with customers is essential for providing a positive dining experience. You agree to promptly respond to customer inquiries, feedback, and special requests to ensure customer satisfaction and loyalty.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">9. Data Privacy and Security</h2>
      <p className="mb-4">
        Maya respects the privacy and security of your restaurant's data. Any information shared or collected during your partnership will be handled in accordance with Maya's Privacy Policy and applicable data protection laws.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">10. Brand Representation</h2>
      <p className="mb-4">
        As a valued partner, you agree to represent your restaurant brand professionally and uphold its reputation while collaborating with Maya. This includes maintaining consistent branding, quality standards, and customer service excellence.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">11. Marketing and Promotion</h2>
      <p className="mb-4">
        Maya may engage in marketing and promotional activities to promote your restaurant and menu offerings to customers. By partnering with Maya, you grant permission for the use of your restaurant's name, logo, and menu images for promotional purposes.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">12. Modification of Terms</h2>
      <p className="mb-4">
        Maya Multipurpose Pvt Ltd reserves the right to modify, update, or revise the terms of this Agreement at any time. You will be notified of any changes, and continued partnership with Maya after such modifications constitutes acceptance of the revised terms.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">13. Termination of Partnership</h2>
      <p className="mb-4">
        Maya reserves the right to terminate your partnership if you breach any terms of this Agreement, fail to meet performance standards, or engage in any conduct deemed detrimental to Maya or its customers. Termination may result in the removal of your restaurant from the Maya platform.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">14. Dispute Resolution</h2>
      <p className="mb-4">
        In the event of any disputes or disagreements arising under this Agreement, both parties agree to engage in good faith negotiations to resolve the matter amicably. If a resolution cannot be reached, disputes may be subject to arbitration as per the laws of Nepal.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">15. Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify and hold Maya Multipurpose Pvt Ltd harmless from any claims, damages, losses, or liabilities arising from your partnership with Maya or any breach of this Agreement.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">16. Governing Law and Jurisdiction</h2>
      <p className="mb-4">
        This Agreement shall be governed by and construed in accordance with the laws of Nepal. Any legal proceedings arising under this Agreement shall be subject to the exclusive jurisdiction of the courts of Nepal.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">17. Entire Agreement</h2>
      <p className="mb-4">
        This Agreement constitutes the entire understanding between you and Maya Multipurpose Pvt Ltd regarding your partnership and supersedes any prior agreements or understandings, whether written or oral.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">18. Survival</h2>
      <p className="mb-4">
        Sections of this Agreement relating to indemnification, dispute resolution, and termination shall survive the termination of your partnership with Maya.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">19. Contact Information</h2>
      <p className="mb-4">
        For inquiries or assistance regarding this Agreement, please contact Maya Multipurpose Pvt Ltd at <a href="mailto:support@mayanp.com" className="text-blue-600">support@mayanp.com</a>.
      </p>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default TermsOfServiceRestaurantPartner;
