import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
function TermsOfServiceCustomer() {
  return (
    <div className='bg-[#F4EFED]'>
        <Navbar/>
    
        <div className="bg-[#F4EFED] font-poppins text-gray-700 py-8 px-8 md:p-16  ">
        <nav className="mb-4  text-xs md:text-sm">
          <Link to="/terms-and-condition" className="text-[#D60620]">Categories</Link>
          <span className="mx-2"><FontAwesomeIcon icon={faChevronRight} /></span>
          <span className="text-[#D60620] ">Terms of service agreement for maya customer</span>
        </nav>
      <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-black font-PPAgrandir">Terms of Service Agreement</h1>
      <div className='text-justify'>
      <p className="mb-4">Maya Multipurpose Pvt Ltd</p>
      <p className="mb-4">Application: Maya</p>
      <p className="mb-4">This comprehensive Terms of Service Agreement ("Agreement") serves as a legally binding contract between Maya Multipurpose Pvt Ltd ("Maya"), a distinguished company registered in Nepal, and you, a valued customer, for your utilization of the esteemed Maya application ("App"). By accessing or using the Maya App, you unequivocally acknowledge and agree to be bound by the terms and conditions delineated herein. Should you dissent from any aspect of this Agreement, kindly refrain from accessing or utilizing the App.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Acceptance of Terms and Conditions</h2>
      <p className="mb-4">By diligently accessing or utilizing the Maya App, you affirmatively acknowledge and accept all the terms and conditions stipulated in this Agreement. Your esteemed usage of the App signifies your unwavering assent to abide by the provisions articulated herein.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Services Offered by Maya</h2>
      <p className="mb-4">Maya proudly offers a diverse array of esteemed services to cater to your esteemed needs and preferences. These esteemed services encompass, but are by no means limited to:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Ride Booking Services: Facilitating the seamless booking of Taxi and Bike rides, thereby ensuring optimal convenience and mobility for esteemed customers.</li>
        <li>Delivery Services: Ensuring the expeditious and reliable delivery of Food and Courier items to esteemed recipients, thereby fostering unparalleled convenience and satisfaction.</li>
        <li>Bus Ticketing Services: Facilitating the hassle-free booking of Bus tickets, thereby ensuring seamless travel arrangements and itinerary planning.</li>
        <li>Hotel Booking Services: Expediting the seamless reservation of Hotel accommodations, thereby ensuring optimal comfort and convenience for esteemed travelers.</li>
        <li>Flight Booking Services: Facilitating the convenient booking of Flight tickets, thereby ensuring seamless travel arrangements and itinerary planning for esteemed travelers.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Booking Procedures for Ride Services (Taxi & Bike)</h2>
      <p className="mb-4">To avail yourself of esteemed Ride Booking services, esteemed customers are courteously urged to diligently adhere to the following procedures:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Launch the Maya App and select the desired ride option (Taxi- Now, Schedule & Sharing or Bike).</li>
        <li>Enter the pickup and drop-off locations, along with any additional preferences or requirements.</li>
        <li>Confirm the booking and await confirmation from the esteemed Maya Partner.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Delivery Services (Food & Courier) Booking and Delivery Process</h2>
      <p className="mb-4">For esteemed Delivery Services, esteemed customers are kindly urged to conscientiously follow the prescribed booking and delivery process, as delineated below:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Access the Maya App and select the desired delivery service (Food or Courier).</li>
        <li>Enter the pickup and delivery addresses, along with any specific instructions or preferences.</li>
        <li>Confirm the booking and await confirmation from the esteemed Maya Partner or delivery personnel.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Bus Ticketing Services and Booking Process</h2>
      <p className="mb-4">To avail esteemed Bus Ticketing services, esteemed customers are courteously encouraged to adhere to the following booking process:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Access the Maya App and select the esteemed Bus Ticketing option.</li>
        <li>Enter the desired origin, destination, travel dates, and any other pertinent details.</li>
        <li>Confirm the booking and proceed with the esteemed payment process.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Hotel Booking Services and Reservation Procedures</h2>
      <p className="mb-4">To facilitate the seamless reservation of Hotel accommodations, esteemed customers are kindly urged to conscientiously adhere to the following procedures:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Launch the Maya App and select the esteemed Hotel Booking option.</li>
        <li>Enter the desired destination, travel dates, and any specific preferences or requirements.</li>
        <li>Browse through the available options, select the preferred hotel, and proceed with the booking process.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">7. Flight Booking Services and Reservation Procedures</h2>
      <p className="mb-4">For esteemed Flight Booking services, esteemed customers are courteously encouraged to adhere to the following reservation procedures:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Access the Maya App and select the esteemed Flight Booking option.</li>
        <li>Enter the desired origin, destination, travel dates, and any specific preferences or requirements.</li>
        <li>Browse through the available options, select the preferred flight, and proceed with the booking process with the esteemed payment method.</li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">8. Account Registration and Security</h2>
      <p className="mb-4">To ensure optimal security and convenience, esteemed customers are courteously encouraged to register an esteemed account on the Maya App. By registering an esteemed account, esteemed customers can avail themselves of enhanced features and personalized services, while also safeguarding their esteemed personal information and transactional data.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">9. Payment Procedures and Billing Information</h2>
      <p className="mb-4">Esteemed customers are kindly urged to familiarize themselves with the esteemed payment procedures and billing information articulated in the Maya App. By diligently adhering to the prescribed payment procedures, esteemed customers can ensure prompt and seamless transactions, thereby fostering optimal convenience and satisfaction.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">10. User Conduct and Responsibilities</h2>
      <p className="mb-4">In recognition of esteemed customers' pivotal role in fostering a harmonious and conducive environment within the esteemed Maya community, it is imperative to underscore the esteemed importance of exemplary user conduct and responsibility. Esteemed customers are courteously urged to treat esteemed Maya Partners and fellow users with the utmost respect, courtesy, and consideration at all times.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">11. Data Privacy and Security</h2>
      <p className="mb-4">Maya Multipurpose Pvt Ltd holds the esteemed privacy and security of esteemed customer data in the highest regard. By accessing or utilizing the Maya App, esteemed customers unequivocally consent to the esteemed collection, processing, and utilization of their esteemed personal information in strict accordance with prevailing data privacy laws and regulatory frameworks.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">12. Communication and Notifications</h2>
      <p className="mb-4">Esteemed customers are courteously encouraged to remain apprised of esteemed communication and notifications disseminated by Maya Multipurpose Pvt Ltd through the esteemed Maya App. By diligently monitoring esteemed communications and notifications, esteemed customers can remain abreast of esteemed updates, promotions, and pertinent information pertaining to esteemed services and offerings.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">13. Modification and Cancellation Policies</h2>
      <p className="mb-4">Maya Multipurpose Pvt Ltd is duly committed to accommodating esteemed customers' evolving needs and preferences to the fullest extent possible. In the esteemed event of any modification or cancellation of esteemed bookings or reservations, esteemed customers are courteously urged to adhere to the prescribed policies and procedures articulated in the Maya App.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">14. Refund and Compensation Policies</h2>
      <p className="mb-4">In the esteemed event of any refund or compensation requests, esteemed customers are courteously urged to liaise with esteemed Maya Multipurpose Pvt Ltd customer support representatives for prompt and expedient resolution. By diligently adhering to the prescribed refund and compensation policies, esteemed customers can ensure optimal satisfaction and resolution.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">15. Intellectual Property Rights</h2>
      <p className="mb-4">All intellectual property rights and proprietary assets associated with the esteemed Maya App and associated services are exclusively owned and retained by Maya Multipurpose Pvt Ltd. Esteemed customers are courteously urged to respect and adhere to esteemed intellectual property rights, trademarks, and copyrights, thereby fostering a culture of mutual respect and appreciation.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">16. Disclaimer of Warranties</h2>
      <p className="mb-4">Maya Multipurpose Pvt Ltd unequivocally disclaims any and all warranties, whether express or implied, pertaining to esteemed services and offerings facilitated through the esteemed Maya App. While Maya tirelessly endeavors to provide services of impeccable quality and reliability, Maya does not warrant or guarantee the accuracy, reliability, or suitability of esteemed services for any particular purpose.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">17. Limitation of Liability</h2>
      <p className="mb-4">In recognition of the esteemed importance of operational prudence and fiscal responsibility, Maya Multipurpose Pvt Ltd shall not, under any circumstances, be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the esteemed utilization of the Maya App or associated services. Esteemed customers are courteously urged to exercise prudence and circumspection in utilizing esteemed services.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">18. Governing Law and Jurisdiction</h2>
      <p className="mb-4">Esteemed customers are courteously urged to acknowledge and comprehend that this esteemed Agreement shall be governed by and construed in strict accordance with the laws of Nepal. In the esteemed eventuality of any disputes or controversies arising under this esteemed Agreement, the esteemed jurisdiction of the courts of Nepal shall be unequivocally recognized and respected.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">19. Dispute Resolution Mechanisms</h2>
      <p className="mb-4">In a commendable demonstration of operational transparency and customer-centricity, esteemed customers are courteously encouraged to amicably resolve any disputes or controversies arising under this esteemed Agreement through diligent negotiation, mediation, or arbitration. By fostering a culture of mutual respect and collaboration, esteemed customers can ensure prompt and expedient resolution of esteemed disputes.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">20. Termination of Services</h2>
      <p className="mb-4">Maya Multipurpose Pvt Ltd reserves the esteemed prerogative to suspend or terminate esteemed access to the Maya App and associated services in the esteemed event of any violation of esteemed terms and conditions, breach of esteemed user conduct guidelines, or engagement in any behavior deemed detrimental to the esteemed reputation or operational integrity of Maya Multipurpose Pvt Ltd. Esteemed customers are courteously urged to adhere to esteemed terms and conditions to ensure uninterrupted access to esteemed services.</p>

      <h2 className="text-xl md:text-2xl font-semibold mb-4">Contact Us</h2>
      <p className="mb-4">For queries or concerns regarding our Terms of Service Agreement or data practices, please contact us at <a href="mailto:support@mayanp.com" className="text-blue-600">support@mayanp.com</a></p>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default TermsOfServiceCustomer;
