import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import userImage from '../image/userimage.png'
import { Navigation, Pagination } from 'swiper/modules';
const content = [
    {
        name: "Ram Prasad Dhakal",
        post: "User",
        question: "What do you think about Maya's courier delivery service?",
        testimonial: "Maya has become an essential part of my routine. The courier delivery service is exceptionally efficient; I use it regularly for sending and receiving packages, and it’s always on time. The app’s hotel booking feature is fantastic for finding great deals and convenient accommodations during my business travels. The integration of multiple services into one app means I can handle everything from transportation to food with ease. It’s a must-have app for anyone looking to simplify their life!",
        time:"1 month"
    },
    {
        name: "Sita Sharma",
        post: "User",
        question: "How does Maya's ride-sharing compare to other services?",
        testimonial: "I’ve been using Maya for its ride-sharing and bus ticketing services, and I couldn’t be happier. The ride-sharing is straightforward and the drivers are always punctual, which is crucial for my busy schedule. The bus ticketing feature has made traveling to different cities hassle-free, with easy booking and reliable schedules. Additionally, the food delivery service has never disappointed—great variety and prompt service. Maya is my go-to app for almost everything, and it has never let me down.",
        time:"1 day"
    },
    {
        name: "Anil Kumar Shah",
        post: "User",
        question: "How has Maya helped you manage your business tasks?",
        testimonial: "Managing multiple tasks can be overwhelming, but Maya has made it so much easier. I regularly use the courier delivery service for my business, and it’s been reliable and efficient. The hotel booking feature is excellent for securing accommodations for business trips, and the bus ticketing service is perfect for planning my travels. Maya’s integration of these services into one app is incredibly valuable, making it my go-to solution for all my needs.",
        time:"4 days"
    },
    {
        name: "Aarti Patel",
        post: "User",
        question: "What do you think of Maya's ride-sharing & food delivery services?",
        testimonial: "I rely on Maya for my daily commute and occasional travel, and it has been a game-changer. The ride-sharing option is quick and cost-effective, and I appreciate the seamless experience of booking rides. The food delivery service is a great bonus—I've discovered new favorite restaurants thanks to Maya. Additionally, booking hotels and bus tickets through the app is straightforward and efficient. Maya truly makes managing various aspects of daily life much simpler and more enjoyable.",
        time:"3 months"
    }
];


function UserVoice() {
    return (
        <div className="bg-[#F4EFED] shadow-lg font-poppins">
            <div className="bg-[#F4EFED] bg-opacity-70 w-full h-full py-8">
                <div className="text-center pt-4 md:pb-8">
                    <h1 className="text-3xl md:text-4xl pb-2 font-semibold text-black font-PPAgrandir">What do our users say?</h1>
                </div>
                <div className="flex justify-center py-4 md:mx-4 ">
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                        modules={[Navigation, Pagination]}
                        navigation
                        pagination={{ clickable: true }}
                        className="w-[90%] md:w-[94%] overflow-visible"
                    >
                        {content.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="border bg-[#f4f8fb] rounded-xl shadow-lg overflow-hidden transform hover:scale-103 transition-transform duration-300 my-4 sm:mx-2">
                                    <div className='flex items-center'>
                                        <div className="flex flex-initial justify-start items-center mb-4 mx-2 my-4 w-16">
                                            <img src={userImage} alt="User" className="object-cover rounded-full w-full hover:opacity-75 transition-opacity duration-300" />
                                        </div>
                                        <div className='mx-4 flex-initial w-40'>
                                            <p className='text-black text-md'>{item.name}</p>
                                            <p className='text-gray-600 text-sm'>{item.post}</p>
                                        </div>
                                    </div>
                                    <div className="pt-4 text-start text-base md:text-lg font-semibold ml-4">
                                        <h2>{item.question}</h2>
                                    </div>
                                    <div className="p-4 text-justify">
                                        <p className="leading-relaxed text-black text-opacity-70 font-serif text-sm md:text-base  md:min-h-[21rem] lg:min-h-[24rem] xl:min-h-[18rem]" >{item.testimonial}</p>
                                    </div>
                                    <div className="pb-2 pt-2 md:pt-0 md:mt-4 px-4 text-justify">
                                        <p className='text-gray-500 text-xs'>{item.time} ago</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

export default UserVoice;