import Footer from "./Footer";
import Navbar from "./Navbar";
import Services from "./Services";
import serviceImage from '../image/servicedesign.webp'
import ScrollReveal from "./ScrollReveal";
import { Helmet } from "react-helmet-async";
function ServicePage() {
    return ( 
        <div className=' bg-[#F4EFED] w-full font-poppins'>
           <Helmet>
        <title>Services - Maya-The Super App</title>
        <meta name='description' content='Experience comprehensive and convenient services with Maya, including taxi rides, food and courier delivery, bus and flight booking, and hotel reservations.' />
        <link rel="canonical" href="https://mayanp.com/services" />
      </Helmet>
      <Navbar />
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[85%] my-8">
          <ScrollReveal>
        <img src={serviceImage} alt="service"  className="w-full h-full object-contain border-4 border-black rounded-3xl"/>
        </ScrollReveal>
        </div>
        
      </div>
      <Services/>
      <Footer />
    </div>
     );
}

export default ServicePage;