import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import App from "./App";
import Policy from "./components/Policy";
import Privacypolicy from "./components/privacy/Privavypolicy";
import ReturnAndRefund from "./components/privacy/Returnandrefund";
import ReferAndEarnPolicy from "./components/privacy/Referandearn";
import RewardsCouponDiscountTerms from "./components/privacy/Reward";
import Terms from "./components/Terms";
import TermsOfServiceCustomer from "./components/termsandcondition/Customer";
import TermsOfServiceBusPartner from "./components/termsandcondition/BusPartner";
import TermsOfServiceHotelPartner from "./components/termsandcondition/HotelPartner";
import TermsOfServiceRestaurantPartner from "./components/termsandcondition/RestaurantPartner";
import TermsOfServiceRideandDeliveryPartner from "./components/termsandcondition/RideandDeliveryPartner";
import MayaPointsTerms from "./components/termsandcondition/MayaPoints";
import About from "./components/Aboutus";
import ServicePage from "./components/Servicepage";
import PartnersPage from "./components/PartnersPage";
import HelpPage from "./components/HelpPage";
import DownloadPage from "./components/DownloadPage";
import BlogPage from "./components/BlogPage";
import BlogDescription from "./components/BlogDescription";
import { useEffect } from "react";
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function RouteDefine() {
    return(
        <BrowserRouter >
         <ScrollToTop />
        <Routes>
          <Route path="/" element={<App/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/services" element={<ServicePage/>}/>
          <Route path="/partner" element={<PartnersPage/>}/>
          <Route path="/help" element={<HelpPage/>}/>
          <Route path="/downloadapp" element={<DownloadPage/>}/>
          <Route path='/privacy'element={<Policy/>}/>
          <Route path='/blogs'element={<BlogPage/>}/>
          <Route path='/blogs/:id'element={<BlogDescription/>}/>
          <Route path="/policy/privacypolicy" element={<Privacypolicy/>} />
          <Route path="/policy/returnandrefund" element={<ReturnAndRefund />} />
          <Route path="/policy/referandearn" element={<ReferAndEarnPolicy />} />
          <Route path="/policy/rewardpolicy" element={<RewardsCouponDiscountTerms />} />
          <Route path='/terms-and-condition'element={<Terms/>}/>
          <Route path='/terms-and-condition/customer'element={<TermsOfServiceCustomer />}/>
          <Route path='/terms-and-condition/partner-bus'element={<TermsOfServiceBusPartner />}/>
          <Route path='/terms-and-condition/partner-hotel'element={<TermsOfServiceHotelPartner />}/>
          <Route path='/terms-and-condition/partner-restaurant'element={<TermsOfServiceRestaurantPartner />}/>
          <Route path='/terms-and-condition/partner-ride-delivery'element={<TermsOfServiceRideandDeliveryPartner />}/>
          <Route path='/terms-and-condition/maya-point'element={<MayaPointsTerms />}/>
         
        </Routes>
      </BrowserRouter>
    )
    
}

export default RouteDefine;