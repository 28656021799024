import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronRight } from "@fortawesome/free-solid-svg-icons";
function RewardsCouponDiscountTerms() {
  return (
    <div className='bg-[#F4EFED]'>
      <Navbar />
      <div className=" text-gray-700 font-poppins py-4 px-4 md:px-8 text-justify">
      <nav className="mb-4 pt-8 text-xs md:text-sm">
                        <Link to="/privacy" className="text-[#D60620]">Categories</Link>
                        <span className="mx-2"><FontAwesomeIcon icon={faChevronRight}/></span>
                        <span className="text-[#D60620] ">Rewards, Coupon, and Discount Terms</span>
                    </nav>
                    <div className="text-center  pb-8">
        <h1 className=" text-3xl md:text-4xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">Rewards, Coupon, and Discount Terms</h1>
      </div>
<div className='mx-4'>
        
        <p className="mb-4">Maya Multipurpose Pvt Ltd</p>
        <p className="mb-4">Application: Maya</p>
        <p className="mb-4">This Rewards, Coupon, and Discount Terms ("Terms") govern the use of rewards, coupons, and discounts ("Promotional Offers") offered by Maya Multipurpose Pvt Ltd ("Maya") within the Maya application ("App"). By participating in or utilizing Promotional Offers, users agree to abide by the terms and conditions outlined herein.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">1. Eligibility</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.1 General Eligibility:</h3>
        <p className="mb-4">Promotional Offers are available to registered users of the Maya application who meet the eligibility criteria specified for each offer.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">1.2 Specific Eligibility:</h3>
        <p className="mb-4">Some Promotional Offers may be subject to specific eligibility criteria, such as user demographics, usage history, or other conditions determined by Maya.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">2. Types of Promotional Offers</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.1 Rewards:</h3>
        <p className="mb-4">Rewards may include Maya Points, cashback, loyalty points, or other incentives provided to users for engaging with Maya services, participating in promotional activities, or meeting specified criteria.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.2 Coupons:</h3>
        <p className="mb-4">Coupons are promotional codes or vouchers that entitle users to discounts, freebies, or other benefits when applied to eligible purchases or transactions within the Maya application.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">2.3 Discounts:</h3>
        <p className="mb-4">Discounts are price reductions or percentage-off offers applied to eligible purchases or transactions within the Maya application, providing users with savings on their total expenditure.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">3. Redemption and Usage</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">3.1 Redemption Process:</h3>
        <p className="mb-4">Users can redeem Promotional Offers by entering valid coupon codes, activating rewards, or participating in designated promotional activities as communicated by Maya.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">3.2 Usage Restrictions:</h3>
        <p className="mb-4">Promotional Offers may be subject to usage restrictions, including but not limited to:</p>
        <ul className="list-disc list-inside mb-4">
          <li>Validity periods: Promotional Offers may have specified start and end dates during which they are valid for redemption.</li>
          <li>Usage limits: Some Promotional Offers may have usage limits per user, per transaction, or overall, as determined by Maya.</li>
          <li>Exclusions: Certain products, services, or transactions may be excluded from Promotional Offers at Maya's discretion.</li>
        </ul>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">4. Terms of Use</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">4.1 Non-Transferable:</h3>
        <p className="mb-4">Promotional Offers are non-transferable and may not be exchanged, transferred, or redeemed for cash or any other form of value outside of the Maya application.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">4.2 Single Use:</h3>
        <p className="mb-4">Unless otherwise specified, each Promotional Offer is intended for single use only and cannot be reused or applied multiple times to the same transaction or purchase.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">4.3 No Retroactive Application:</h3>
        <p className="mb-4">Promotional Offers cannot be applied retroactively to previous purchases, transactions, or activities conducted before the offer's availability or activation.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">5. Modification and Termination</h2>

        <h3 className="text-md md:text-xl font-semibold mb-2">5.1 Modification:</h3>
        <p className="mb-4">Maya Multipurpose Pvt Ltd reserves the right to modify, update, or revise the terms and conditions of Promotional Offers at any time without prior notice. Users will be notified of any changes, and continued participation in or utilization of Promotional Offers after such modifications constitutes acceptance of the revised terms.</p>

        <h3 className="text-md md:text-xl font-semibold mb-2">5.2 Termination:</h3>
        <p className="mb-4">Maya reserves the right to terminate, suspend, or cancel any Promotional Offer at its discretion, without liability, if deemed necessary due to fraud, abuse, technical issues, or any other reason.</p>

        <h2 className="text-xl md:text-2xl font-semibold mb-4">6. Contact Information</h2>
        <p className="mb-4">For inquiries or assistance regarding Promotional Offers, please contact Maya customer support at support@mayanp.com.</p>

        <p className="mt-8">These Rewards, Coupon, and Discount Terms outline the terms and conditions governing the use of Promotional Offers within the Maya application. Should you have any questions or concerns regarding these terms or any specific Promotional Offer, please do not hesitate to contact us.</p>
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default RewardsCouponDiscountTerms;
