
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet-async";
import Footer from "./Footer";
import Navbar from "./Navbar";
import BlogImage from "../image/blog.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
function BlogDescription() {
    const location = useLocation();
    const { blog } = location.state;
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className=' bg-[#F4EFED] w-full font-poppins'>
            <Helmet>
                <title>Blog-{blog.title} - Maya-The Super App</title>
                <meta name='description' content={blog.introduction} />
                <link rel="canonical" href={`https://mayanp.com/blogs/${blog.title}`} />
            </Helmet>
            <Navbar />
            <div className="w-full  font-poppins flex justify-center">
                <div className='max-w-4xl'>
                    <div className="text-center pt-4 md:pt-8 pb-4 px-2">
                        <h1 className="  text-xl md:text-3xl pb-2 font-semibold text-black  inline-block  md:pb-4 font-PPAgrandir">{blog.title}</h1>
                        <p className='text-xs text-gray-500 '><FontAwesomeIcon icon={faClock} className='' /> July 01, 2024</p>
                    </div>
                    <div className="w-full mx-2 sm:mx-4  md:mx-4  rounded-2xl md:p-6 p-2">
                        <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-4">
                            {blog.introduction}
                        </p>
                    </div>
                    <div className="w-full flex justify-center items-center h-auto mb-4 py-4">
                        <img src={BlogImage} alt="Maya Bike" className="w-[60%]  object-cover rounded-3xl  transition-opacity duration-300" />
                    </div>

                    <div className="w-full mx-2 sm:mx-4  md:mx-4  rounded-2xl md:p-6 p-2">

                        {Object.entries(blog.content).map(([sectionTitle, paragraphs], index) => (
                            <div key={index} className="section">
                                <h2 className="text-lg md:text-xl font-semibold mb-4">{sectionTitle}</h2>
                                {sectionTitle === "How It Works" ? (
                                    <>
                                    <ul className="list-disc ml-6 text-gray-700 text-base md:text-lg leading-relaxed ">
                                        <li>{paragraphs[0]}</li> 
                                    </ul>
                                    <ul className="list-decimal ml-6 text-gray-700 text-base md:text-lg leading-relaxed mb-4 ">
                                        {paragraphs[1].map((paragraph, idx) => (
                                            <li className='ml-2'key={idx}><strong>{paragraph.split(':')[0]}:</strong>{paragraph.split(':')[1]}</li>
                                        ))}
                                    </ul>
                                    <ul className="list-disc ml-6 text-gray-700 text-base md:text-lg leading-relaxed mb-4">
                                        <li>{paragraphs[2]}</li> 
                                    </ul>
                                </>
                                ) : (
                                    <ul className="list-disc ml-6 text-gray-700 text-base md:text-lg leading-relaxed mb-4">
                                        {paragraphs.map((paragraph, idx) => (
                                            <li key={idx}>{paragraph}</li>
                                            
                                        ))}
                                    </ul>
                                )}

                            </div>
                        ))}
                        <p className="text-gray-700 text-base md:text-lg leading-relaxed mb-4">
                            {blog.conclusion}
                        </p>
                    </div>
                </div>


            </div>
            <Footer />
        </div>
    );
}

export default BlogDescription;
